import React, { useState, useEffect } from 'react';
import s from './AreaPageSite.module.scss'
import MainTitle from '../../ui/MainTitle/MainTitle';
import HelmetUI from '../../ui/HelmetUI/HelmetUI';
import { Routes, Route } from "react-router-dom";
import AreaAPI from '../../utils/AreaAPI';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Scrollbar, Navigation } from "swiper";
import SubTitle from '../../ui/SubTitle/SubTitle';

const AreaPageSite = () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	const imgPath = "https://teatrcdr.ru/teatr-api/img/area/"

	useEffect(() => {
		AreaAPI.getArea()
			.then(result => {
				if (result.status === 200) {
					// //console.log(result.data)
					setData(result.data)
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	// // console.log(data)

	return (
		<div>
			<div className={s.container}>
				<Routes>
					<Route index element={<HelmetUI title="Площадки - Центр драматургии и режиссуры" description="Театр Центр драматургии и режиссуры" />} />
				</Routes>

				<div className={s.content}>
					{loading === true &&
						<div className={s.flex_container}>
							<div className={s.carousel}>
								<Swiper
									spaceBetween={30}
									effect={"fade"}
									scrollbar={{
										hide: true,
									}}
									autoplay={{
										delay: 5000,
										disableOnInteraction: false,
									}}
									loop={true}
									modules={[EffectFade, Autoplay, Scrollbar, Navigation]}
									navigation
									className={s.imageContainer}
									style={{
										'--swiper-navigation-color': '#fff',
										'--swiper-pagination-color': '#fff',
									}}
								>
									{data?.map(area => {
										return area?.picture?.map(image => {
											return <SwiperSlide key={image}>
												<div className={s.carouselItem}>
													<img className={s.carouselImg} key={image} src={imgPath + area?.areaID + '/img/' + image} alt={image} />
												</div>

											</SwiperSlide>
										})
									})}
								</Swiper>
							</div>
							<div>
								<MainTitle>Площадки</MainTitle>
								<div className={s.areaList}>
									{data?.map(area => {
										return <div>
											<div id={"area-" + area.id}></div>
											<div className={s.areaItem} key={area.areaID}>
												<div className={s.areaImg}>
													{/* <img src={imgPath + area?.areaID + '/img/' + area?.picture[0]}></img> */}
													<div className={s.carouselLitle}>
														<Swiper
															spaceBetween={30}
															effect={"fade"}
															scrollbar={{
																hide: true,
															}}
															autoplay={{
																delay: 5000,
																disableOnInteraction: false,
															}}
															loop={true}
															modules={[EffectFade, Autoplay, Scrollbar, Navigation]}
															navigation
															className={s.imageLitleContainer}
															style={{
																'--swiper-navigation-color': '#fff',
																'--swiper-pagination-color': '#fff',
															}}
														>

															{area?.picture?.map(image => {
																return <SwiperSlide key={image}>
																	<div className={s.carouselItem}>
																		<img className={s.carouselImg} key={image} src={imgPath + area?.areaID + '/img/' + image} alt={image} />
																	</div>
																</SwiperSlide>
															})}

														</Swiper>
													</div>
												</div>
												<div className={s.areaInformation}>
													<SubTitle>{area.name}</SubTitle>
													<div dangerouslySetInnerHTML={{ __html: area.text }}></div>
													<div className={s.areaDoc}>
														{area.doc.length !== 0 && area.doc.map(doc => {
															return <a key={doc} href={imgPath + area?.areaID + '/doc/' + doc} target='_blank'>{doc}</a>
														})}
													</div>

												</div>

											</div>
											<div className={s.map} dangerouslySetInnerHTML={{ __html: area.description }}></div>
										</div>
									})}
								</div>
							</div>

						</div>}

				</div>
			</div>
		</div >
	);
}

export default AreaPageSite;
