import React, { useState, useEffect } from 'react';
import s from './HistoryPageSite.module.scss'
import MainTitle from '../../ui/MainTitle/MainTitle';
import HelmetUI from '../../ui/HelmetUI/HelmetUI';
import { Routes, Route } from "react-router-dom";
import PageAPI from '../../utils/PageAPI';

const HistoryPageSite = () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		PageAPI.getHistory()
			.then(result => {
				// //console.log(result.data);
				if (result.status === 200) {
					setData(result.data[0])
					// setImgArray(result.data[0].picture)
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	// //console.log(data)
	return (
		<div>
			<div className={s.container}>
				<Routes>
					<Route index element={<HelmetUI title="История - Центр драматургии и режиссуры" description="Театр Центр драматургии и режиссуры" />} />
				</Routes>

				<div className={s.content}>
					{loading === true &&
						<div className={s.flex_container}>
							<div>
								<MainTitle>История</MainTitle>
								<div className={s.text} dangerouslySetInnerHTML={{ __html: data.text }}></div>
							</div>

							<div className={s.imgList}>
								{data?.picture[0].map(item => {
									return (
										<div className={s.img} key={item}>
											<img src={'https://teatrcdr.ru/teatr-api/img/history/' + item} alt="История ЦДР" />
										</div>
									)
								})}

							</div>
						</div>}

				</div>
			</div>
		</div >
	);
}

export default HistoryPageSite;
