import React, { useState, useEffect } from 'react';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import IconButton from '@mui/material/IconButton';
import s from './UserEditPage.module.scss'
import { Helmet } from 'react-helmet-async';
import UserForm from '../../../components/Admin/Access/UserForm/UserForm';
import UserAPI from '../../../utils/UserAPI'
import CircularProgress from '@mui/material/CircularProgress';
const inflection = require('inflection');

const UserEditPage = () => {
	const [user, setUser] = useState({});
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { id } = useParams();

	const cancel = () => {
		navigate(-1)
	}

	useEffect(() => {
		loading === false &&
			UserAPI.getUser(id)
				.then(result => {
					//console.log(result);
					if (result.status === 200) {
						setUser(result.data)
						setLoading(true)
					}

					if (result.response?.status === 500) {
						//console.log(result.response.data)
					}
				})
	}, [loading, id]);

	const renderEditUser = () => {
		return (
			<>
				<Helmet>
					<title>{inflection.titleize(user?.fio)}</title>
					<meta name="description" content={inflection.titleize(user?.fio)} />
					<meta name="robots" content="none" />
				</Helmet>

				<div className={s.title}>
					<IconButton sx={{ mb: '10px', borderRadius: '0px', border: '1px solid' }} aria-label="delete" color='primary' onClick={() => cancel()}>
						<ArrowBackOutlinedIcon />
					</IconButton>
					<MainTitle>{inflection.titleize(user?.fio)}</MainTitle>
				</div>

				<div className={s.content}>
					<UserForm data={user}/>
				</div>
			</>
		)
	}

	return (
		<>
			{
				loading === true
					?
					renderEditUser()
					:
					<CircularProgress />
			}
		</>

	)
}

export default UserEditPage;
