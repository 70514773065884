import Reactfrom from 'react';
import s from "./CafePage.module.scss"
import { Link, Routes, Route } from "react-router-dom";
import MainTitle from '../../../ui/MainTitle/MainTitle';
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';
import CafeForm from '../../../components/Admin/Cafe/CafeForm/CafeForm';

const CafePage = () => {

	return (
		<>
			<Routes>
				<Route index element={<HelmetUI title="ЦДР кафе" description="Управление страницей ЦДР кафе" robots="none" />} />
			</Routes>

			<Routes>
				<Route index element={<MainTitle>ЦДР кафе</MainTitle>} />
			</Routes>

			{/* <Routes>
				<Route index element={
					<div className={s.add}>
						<Link to='./add'>
							<Button sx={{ borderRadius: '0px' }} variant="outlined">Добавить</Button>
						</Link>
					</div>
				} />
			</Routes> */}


			<div className={s.content}>
				<Routes>
					<Route index element={<CafeForm g1={true}/>} />
					{/* <Route path="add" element={<NewsForm />} />
					<Route path=":id" element={<NewsForm g1={true} />} /> */}
				</Routes>
			</div>
		</>
	);
}

export default CafePage;
