import React, { useEffect, useState } from 'react';
import s from "./VacanciesForm.module.scss";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { nanoid } from 'nanoid'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PerformanceAPI from '../../../../utils/PerformanceAPI';
import PageAPI from '../../../../utils/PageAPI';
import NewsAPI from '../../../../utils/NewsAPI';
import InputFileUI from '../../../../ui/InputFileUI/InputFileUI';
import axios from 'axios'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const inflection = require('inflection')

const VacanciesForm = ({ g1 }) => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState([]);
	const [imgArray, setImgArray] = useState([]);
	const [loadingFiles, setloadingFiles] = useState(false);
	const [prew, setPrew] = useState([]);
	const [show, setShow] = useState([]);
	const { id } = useParams()
	const navigate = useNavigate();
	const [redactor, setRedactor] = useState(data.text);

	const { control, handleSubmit, formState: { errors, isDirty, isValid }, setValue, getValues } = useForm(
		{
			mode: "onSubmit"
		}
	);

	// //console.log(redactor)

	useEffect(() => {
		PageAPI.getPage('vacancies')
			.then(result => {
				// //console.log(result.data);
				if (result.status === 200) {
					setData(result.data[0])
					setRedactor(result.data[0].text)
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	useEffect(() => {
		// setValue('newsID', nanoid());
		// setValue('picture[]', file);
		setValue('name', 'vacancies');
	}, [setValue, data, loading, file, setImgArray]);

	useEffect(() => {
		(loading === true && g1) && (urlToObject())
		setloadingFiles(true)
	}, [loading]);

	//Добавить событие
	const add = (form) => {
		form.showID = form.showID.join()
		form.publish = form.publish === true ? 1 : 0;
		form.mainPage = form.mainPage === true ? 1 : 0;

		//console.log(form)

		NewsAPI.addNews(form)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Изменить событие
	const update = (form) => {
		form.text = redactor;

		//console.log(form)
		PageAPI.updatePage(form)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					// navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Удалить событие
	const deletePerformance = () => {
		// //console.log('Delete')
		NewsAPI.deleteNews(id)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Загрузка фото соответствующего сотрудника
	const urlToObject = async () => {
		const dt = new DataTransfer();

		const downloadImg = await Promise.all(
			imgArray.map(async item => {
				await axios.get(`https://teatrcdr.ru/teatr-api/img/history/${item}`, {
					responseType: 'blob'
				})
					.then(res => {
						// //console.log(res)
						res.headers['content-type'].includes('image') &&
							dt.items.add(new File([res.data], `https://teatrcdr.ru/teatr-api/img/history/${item}`, { type: res.headers['content-type'] }));
					})
					.catch(err => err)
			})
		)

		var file_list = dt.files;
		setFile(file_list);
		setPrew(file_list);
	}

	const toolbarOptions = {
		toolbar:[
		['bold', 'italic', 'underline', 'strike'],        
		['blockquote', 'code-block'],
		[{ 'header': [1, 2, 3, 4, 5, 6, false] }],               
		[{ 'list': 'ordered' }, { 'list': 'bullet' }],                   
		['image','link'],
		[{ 'align': [] }],
		['clean']                                         
	]
};

	//Отрисовка формы
	const renderForm = () => {
		return (
			<>
				<form
					className={s.form}
					onSubmit={
						g1 ? handleSubmit(update) : handleSubmit(add)
					}>

					{/* <InputFileUI file={loadingFiles === true && prew} setFile={setFile} /> */}

					<div className={s.editor}>
						<ReactQuill theme="snow" modules={toolbarOptions} value={redactor} onChange={setRedactor} />

					</div>

					<div className={s.button}>
						<Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => navigate(-1)}>
							<>Назад</>
						</Button>

						<Button sx={{ borderRadius: '0px' }} variant="outlined" type='submit'>
							{g1 !== undefined ? <>Изменить</> : <>Создать</>}
						</Button>
					</div>

				</form>
			</>
		)
	}

	return (
		<div className={s.container}>
			{loading === true
				?
				renderForm()
				:
				<CircularProgress />
			}
		</div>
	);
}

export default VacanciesForm;
