import React, { useState, useEffect } from 'react';
import AreaAPI from "../../utils/AreaAPI";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import PerformanceAPI from '../../utils/PerformanceAPI'
import Fade from 'react-reveal/Fade';
import {Autoplay, EffectFade, Navigation, Scrollbar} from "swiper";

import s from "../ShowSite/ShowListSite/ShowListSite.module.scss";
import logo from "../NewsSite/NewsListHome/img/logo.jpg";
import moment from "moment/moment";


const HomeAreas = () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	const imgPath = "https://teatrcdr.ru/teatr-api/img/area/"

	useEffect(() => {
		AreaAPI.getArea()
			.then(result => {
				if (result.status === 200) {
					// //console.log(result.data)
					setData(result.data)
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);


	return (

		<div className={s.showList}>
			{data.map((area) => {

				return (
					<Fade big key={area?.areaID}>
						<Card elevation={3} sx={{ height: '100%', position: 'relative', borderRadius: '0', '&:hover .MuiCardMedia-root, &:focus .MuiCardMedia-root': { transform: 'scale(1.1) ', transition: "all 1.1s ease" } }} key={area?.areaID}>

							<div className={s.carouselItem}>
								{/* {console.log(area.picture)} */}
								<HashLink to={`/o-teatre/ploshhadki#area-${area?.id}`} >
									<img className={s.carouselImg} src={area.picture[0] ? imgPath + area?.areaID + '/img/' + area.picture[0] : logo} alt={area.areaID} />
								</HashLink>
							</div>

							<CardContent sx={{ minHeight: '0', height: '100%', backgroundColor: 'white', zIndex: 10, position: 'relative' }}>
								<HashLink to={`/o-teatre/ploshhadki#area-${area?.id}`} >
									<div className={s.showTitle}>
										{area.name}
									</div>
								</HashLink>

								<div className={s.place}>
									<span className={s.placeTitle}>Адрес:</span> <span className={s.placeName}>{area.address}</span>
								</div>


							</CardContent>
						</Card>
					</Fade>
				)
			})}
		</div >


	);


}

export default HomeAreas;