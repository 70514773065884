import React from 'react';
import { Helmet } from 'react-helmet-async';

const HelmetUI = ({title, description, robots}) => {
	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta name="robots" content={robots} />
			</Helmet>
		</>
	);
}

export default HelmetUI;
