import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import s from './LayoutAdmin.module.scss'
import cn from 'classnames'
import { Outlet } from "react-router-dom";
import Logo from '../Logo/Logo';
import AdminMenu from '../AdminMenu/AdminMenu';
import { ThemeProvider, createTheme } from "@mui/material/styles";



const LayoutAdmin = ({children}) => {
	const [openLeftMenu, setopenLeftMenu] = useState(false);

	const clickToOpenLeftMenu = () => {
		openLeftMenu === false
			? setopenLeftMenu(true)
			: setopenLeftMenu(false)
	}

	const theme = createTheme({
		palette: {
			mode: 'dark',
			primary: {
				main: '#ffffff',
			},
			secondary: {
				main: '#f50057',
			},
		},
	});

	return (
		<>
			<div className={cn(
				s.topNavbar,
			)}>
				<>
					<Logo size='little'>
						Центр драматургии<br /> и режиссуры
					</Logo>
				</>
				<div className={s.btnBlock}>
					<IconButton
						size="large"
						edge="start"
						color="primary"
						aria-label="open drawer"
						sx={{ mr: 2, border: '1px solid', borderRadius: '0px', marginRight: 0 }}
						onClick={() => clickToOpenLeftMenu()}
					>
						{openLeftMenu === true ? <MenuOpenIcon size='large' /> : <MenuIcon size='large' />}

					</IconButton>
				</div>
			</div>
			<div className={s.container}>
				<div className={cn(
					s.dashboardWrapper,
					openLeftMenu === true && s.open)
				}>
					<ThemeProvider theme={theme}>
						<div className={s.dashboardContent}>
							
							<AdminMenu clickToOpenLeftMenu={clickToOpenLeftMenu} />
							
						</div>
					</ThemeProvider>
				</div>
				<div className={s.contentWrapper}>
					<div className={s.content}>
						<Outlet />
					</div>


				</div>
			</div>
		</>


	);
}

export default LayoutAdmin;
