import React, {useState, useEffect}from 'react';
import s from "./LabsPage.module.scss"
import { Link, Routes, Route } from "react-router-dom";
import Button from '@mui/material/Button';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';
import ShowList from '../../../components/Admin/Show/ShowList/ShowList';
import ShowForm from '../../../components/Admin/Show/ShowForm/ShowForm';
import LabList from '../../../components/Admin/Labs/LabList/LabList';
import LabForm from '../../../components/Admin/Labs/LabForm/LabForm';

const LabsPage = () => {

	return (
		<>
			<Routes>
				<Route index element={<HelmetUI title="Лаборатории" description="Управление лабораториями театра" robots="none" />} />
				<Route path="add" element={<HelmetUI title="Добавление лаборатории" description="Добавление новой лаборатории" robots="none" />} />
				<Route path=":id" element={<HelmetUI title="Редактирование лаборатории" description="Редактирование лаборатории" robots="none" />} />
			</Routes>

			<Routes>
				<Route index element={<MainTitle>Лаборатории</MainTitle>} />
				<Route path=":id" element={<MainTitle back>Редактировать</MainTitle>} />
				<Route path="add" element={<MainTitle back>Добавление лаборатории</MainTitle>} />
			</Routes>

			<Routes>
				<Route index element={
					<div className={s.add}>
						<Link to='./add'>
							<Button sx={{ borderRadius: '0px' }} variant="outlined">Добавить</Button>
						</Link>
					</div>
				} />
			</Routes>


			<div className={s.content}>
				<Routes>
					<Route index element={<LabList />} />
					<Route path="add" element={<LabForm />} />
					<Route path=":id" element={<LabForm g1={true} />} />
				</Routes>
			</div>
		</>
	);
}

export default LabsPage;
