import React, { useState, useEffect } from 'react';
import s from './TicketPageSite.module.scss'
import MainTitle from '../../ui/MainTitle/MainTitle';
import HelmetUI from '../../ui/HelmetUI/HelmetUI';
import { Routes, Route } from "react-router-dom";
import PageAPI from '../../utils/PageAPI';

const TicketPageSite = () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		PageAPI.getPage('ticket')
			.then(result => {
				// //console.log(result.data);
				if (result.status === 200) {
					setData(result.data[0])
					// setImgArray(result.data[0].picture)
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	// //console.log(data)
	return (
		<div>
			<div className={s.container}>
				<Routes>
					<Route index element={<HelmetUI title="Купить билеты - Центр драматургии и режиссуры" description="Театр Центр драматургии и режиссуры" />} />
				</Routes>

				<div className={s.content}>
					{loading === true &&
						<div className={s.flex_container}>
							<div>
								<MainTitle>Купить билеты</MainTitle>
								<div className={s.text} dangerouslySetInnerHTML={{ __html: data.text }}></div>
							</div>
						</div>}

				</div>
			</div>
		</div >
	);
}

export default TicketPageSite;
