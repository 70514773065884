import React, { useState, useEffect } from 'react';
import s from './ShowSliderListSite.module.scss'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// import { format } from 'date-fns'
// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import PerformanceAPI from '../../../utils/PerformanceAPI'
import Fade from 'react-reveal/Fade';
import moment from 'moment';
import logo from './img/logo.jpg'
import {Autoplay, EffectFade, Navigation, Scrollbar} from "swiper";


const ShowSliderListSite = ({ id }) => {
	//console.log(id)

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [url, setUrl] = useState('');

	useEffect(() => {

			PerformanceAPI.getOnePerformance(id)
				.then(result => {
					// //console.log(result);
					if (result.status === 200) {
						setData(result.data)
					}

					if (result.response?.status === 406) {
						// //console.log(result.response.data)
						setData([])
					}

					if (result.response?.status === 500) {
						// //console.log(result.response.data)
					}
				})
	}, [loading, id]);



	const handleOpen = (url) => {
		setUrl(url);
		// setOpen(true);
		window.TLIframe.showPopup({ url: url })
	}

	const handleClose = () => {
		setUrl('');
		setOpen(false);
	}

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		height: '80%',
		bgcolor: 'background.paper',
		padding: '20px 5px'
	};

	const imgPath = "https://teatrcdr.ru/teatr-api/img/performance/"
	// const [imgArray, setImgArray] = useState(data.picture.split('\n'));

	const sortData = data.sort((prev, next) => {
		return new Date(prev.sort) - new Date(next.sort)
	})

	const sliceData = data.slice(0, 4);


	//console.log(sliceData)
	//console.log(data)

	return (

		<div>
			<Swiper
				autoHeight={true}
				spaceBetween={30}
				className={s.eventsSlider}
				slidesPerView={1}
				slidesPerGroup={1}
				loop={false}
				modules={[Scrollbar, Navigation]}
				navigation
				style={{
					'--swiper-navigation-color': '#fff',
					'--swiper-pagination-color': '#fff',
					'--swiper-navigation-top-offset': 'calc(50% - 4vw)',
				}}
				breakpoints={{
					770: {
						slidesPerView: 2,
						slidesPerGroup: 2,
					},
					1440: {
						slidesPerView: 3,
						slidesPerGroup: 3,
					}
				}}
			>
				{(id ? sliceData : data).map((show) => {

					const str = show.description.slice(0, 200) + '...';
					return <SwiperSlide
						style={{
							// height: 'auto',
							// minHeight: '100%',
							// display: 'flex',
							// flexDirection: 'column',
						}}
						key={show.eventID}
					>

							<Card elevation={3} sx={{
								// height: '100%',
								position: 'relative',
								borderRadius: '0',
								'&:hover .MuiCardMedia-root, &:focus .MuiCardMedia-root': {
									transform: 'scale(1.1) ',
									transition: "all 1.1s ease"
								}
							}} key={show?.showID}>

								<div className={s.carouselItem}>
									<Link to={`/spektakli/${show?.performance}`} >
										<img className={s.carouselImg}
												 src={show.picture[0] ? imgPath + show.performance + '/' + show.picture[0] : logo} alt={show.showID}/>
									</Link>
								</div>

								<CardContent sx={{minHeight: '0', height: '100%', backgroundColor: 'white', zIndex: 10, position: 'relative' }}>
									<Link to={`/spektakli/${show?.performance}`} >
										<div className={s.showTitle}>
											{show.performanceName}
										</div>
									</Link>

									{show?.eventDate &&

										<>
											<div className={s.events}>
												<div className={s.eventStyle} key={show.eventDate} onClick={() => handleOpen(show.ticketland)}>
													{moment(show.eventDate).format('DD.MM.yyyy HH:mm')}

												</div>
											</div>
										</>

									}

									<div className={s.place}>
										<span className={s.placeTitle}>Площадка:</span> <span className={s.placeName}>{show.area}</span>
									</div>

								</CardContent>
							</Card>

					</SwiperSlide>
				})}
			</Swiper>
		</div>

	// <div className={s.showList}>
	// 	{(id ? sliceData : data).map((show) => {
	// 		const str = show.description.slice(0, 200) + '...';
	//
	//
	// 		return (
	// 			<Fade big key={show?.showID}>
	//
	// 			</Fade>
	// 			)
	// 		})}
	// 	</div >


	);
}

export default ShowSliderListSite;
