import React from 'react';
import s from './CompactContacts.module.scss'
import { Link } from 'react-router-dom'

const CompactContacts = () => {
	return (
		<div className={s.contacts}>
			<div className={s.address}>
				Ленинградский пр-т, 71г <br/>
				ул. Беговая, 5<br/>
				ул. Поварская, 20<br/>
			</div>
			<div className={s.phones}>
				<Link to='tel:+74997400994'>8 (905) 740-09-94</Link>
				<Link to='tel:+74997280767'>8 (499) 728-07-67</Link>
			</div>
		</div>
	);
}

export default CompactContacts;
