import axios from 'axios'

class Api {
	constructor() {
		this._baseUrl = 'https://teatrcdr.ru/teatr-api';
	}

	//Добавить новость
	addNews(data) {
		return axios.post(`${this._baseUrl}/news`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Изменить новость
	updateNews(id, data) {
		return axios.post(`${this._baseUrl}/news/${id}`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Удалить новость
	deleteNews(id) {
		return axios.delete(`${this._baseUrl}/news/${id}`)
			.then(res => res)
			.catch(err => err)
	}

	//Получить все новости
	getNews(limit) {
		return axios.get(`${this._baseUrl}/news`, {
			params: {
				limit: limit
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Получить новость по ID
	getNewsForId(id) {
		return axios.get(`${this._baseUrl}/news/${id}`)
			.then(res => res)
			.catch(err => err)
	}

	//Получить новости на период
	getNewsForSite(start, end) {
		return axios.get(`${this._baseUrl}/newsForSite`, {
			params: {
				start: start,
				end: end
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Получить новости для главной страницы
	getNewsForMainPage(limit) {
		return axios.get(`${this._baseUrl}/newsForMainPage`, {
			params: {
				limit: limit
			}
		})
			.then(res => res)
			.catch(err => err)
	}

}

const NewsAPI = new Api();

export default NewsAPI;