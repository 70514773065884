import React, { useState, useEffect } from 'react';
import s from './LabsInformation.module.scss'
import { useNavigate, useParams, Link } from "react-router-dom";
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';
import PerformanceAPI from '../../../utils/PerformanceAPI';
import LabsAPI from '../../../utils/LabsAPI';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Scrollbar, Navigation } from "swiper";
import Grid from '@mui/material/Unstable_Grid2';
import pushkin from './img/pushkin.jpg'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns'
import moment from 'moment';

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/scrollbar";
import "swiper/css/navigation";

const LabsInformation = () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [url, setUrl] = useState('');

	const handleOpen = (url) => {
		setUrl(url);
		setOpen(true);
	}
	const handleClose = () => {
		setUrl('');
		setOpen(false);
	}
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		height: '80%',
		bgcolor: 'background.paper',
		padding: '20px 5px'
	};

	const { id } = useParams()
	const navigate = useNavigate();

	const imgPath = "https://teatrcdr.ru/teatr-api/img/labs/"

	useEffect(() => {
		LabsAPI.getLabForId(id)
			.then(result => {
				// //console.log(result.data[0]);
				if (result.status === 200) {
					setData(result.data[0])
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	const params = {
		spaceBetween: 30,
		effect: 'fade',
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
	}

	//console.log(data)

	return (
		<>
			{loading === true &&
				<div className={s.showInformation} >
					<HelmetUI title={data.name} description={data.name} />
					{data.picture.length > 0 &&
						<div className={s.carousel}>
							<Swiper
								spaceBetween={30}
								effect={"fade"}
								scrollbar={{
									hide: true,
								}}
								autoplay={{
									delay: 5000,
									disableOnInteraction: false,
								}}
								loop={true}
								modules={[EffectFade, Autoplay, Scrollbar, Navigation]}
								navigation
								className={s.imageContainer}
								style={{
									'--swiper-navigation-color': '#fff',
									'--swiper-pagination-color': '#fff',
								}}
							>
								{data?.picture?.map(image => {
									return <SwiperSlide key={image}>
										<div className={s.carouselItem}>
											<img className={s.carouselImg} key={image} src={imgPath + id + '/' + image} alt={image} />
										</div>

									</SwiperSlide>
								})}
							</Swiper>
						</div>

					}

					<Grid container>

						<Grid xs={12} sm={9} sx={{ pr: '15px' }} order={{ xs: 2, sm: 1 }}>
							<MainTitle>{data.name.toUpperCase()}</MainTitle>

							<div className={s.age}>
								<span className={s.ageTitle}>{data.age}</span>
							</div>

							<div className={s.text} dangerouslySetInnerHTML={{ __html: data.text }}></div>

						</Grid>

					</Grid>


				</div>
			}
		</>
	);
}


export default LabsInformation;
