import React, { useEffect, useState } from 'react';
import s from "./AreaForm.module.scss";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { nanoid } from 'nanoid'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputFileUI from '../../../../ui/InputFileUI/InputFileUI';
import SubTitle from '../../../../ui/SubTitle/SubTitle';
import CircularProgress from '@mui/material/CircularProgress';
import PerformanceAPI from '../../../../utils/PerformanceAPI';
import axios from 'axios'
import TextEditor from '../../../../ui/TextEditor/TextEditor';
import LabsAPI from '../../../../utils/LabsAPI';
import AreaAPI from '../../../../utils/AreaAPI';
import InputDocFileUI from '../../../../ui/InputDocFileUI/InputDocFileUI';

const inflection = require('inflection')


const AreaForm = ({ g1 }) => {

	const [data, setData] = useState([]);
	const [file, setFile] = useState([]);
	const [doc, setDoc] = useState([]);
	const [loadingFiles, setloadingFiles] = useState(false);
	const [prew, setPrew] = useState([]);
	const [prewDoc, setPrewDoc] = useState([]);
	const [loading, setLoading] = useState(false);
	const [redactor, setRedactor] = useState();

	const { id } = useParams()
	const navigate = useNavigate();
	const { control, handleSubmit, formState: { errors }, setValue, register } = useForm(
		{
			mode: "onSubmit"
		}
	);

	useEffect(() => {
		(loading === true && g1) && urlToObject(); urlDocToObject()
		setloadingFiles(true)
	}, [loading]);

	useEffect(() => {
		g1 &&
			AreaAPI.getAreaForId(id)
				.then(result => {
					// //console.log(result.data[0]);
					if (result.status === 200) {
						setData(result.data[0])
						setFile(result.data[0]?.picture)
						setDoc(result.data[0]?.doc)
						setRedactor(result.data[0].text)
						setLoading(true)
					}

					if (result.response?.status === 500) {
						// //console.log(result.response.data)
					}
				})

		g1 === undefined && setLoading(true)

	}, [g1]);

	useEffect(() => {
		setValue('areaID', nanoid());
		setValue('picture[]', file);
		setValue('doc[]', doc);
	}, [setValue, file, doc, data, loading]);

	//Загрузка фото к площадки
	const urlToObject = async () => {
		const dt = new DataTransfer();

		// const dwnFile = (item) => {
		// 	axios.get(`https://teatrcdr.ru/teatr-api/img/area/${data?.areaID}/img/${item}`, {
		// 		responseType: 'blob',
		// 		onDownloadProgress: function (progressEvent) {
		// 			let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
		// 		},
		// 	})
		// 		.then(res => {
		// 			dt.items.add(new File([res.data], `https://teatrcdr.ru/teatr-api/img/area/${data?.areaID}/img/${item}`, { type: res.headers['content-type'] }));
		// 			setFile(dt.files);
		// 			setPrew(dt.files);
		// 		})
		// 		.catch(err => err)
		// }

		// for (var i = 0; i < file.length; i++) {
		// 	dwnFile(file[i]);
		// }

		const downloadImg = await Promise.all(
			file.map(async item => {
				await axios.get(`https://teatrcdr.ru/teatr-api/img/area/${data?.areaID}/img/${item}`, {
					responseType: 'blob'
				})
					.then(async res => {
						await dt.items.add(new File([res.data], `${item}`, { type: 'image/jpg' }));
					})
					.catch(err => err)
			})
		)
		var file_list = dt.files;
		setFile(file_list);
		setPrew(file_list);
	}

	//Загрузка документов к площадке
	const urlDocToObject = async () => {
		const dt = new DataTransfer();

		const downloadImg = await Promise.all(
			doc.map(async item => {
				await axios.get(`https://teatrcdr.ru/teatr-api/img/area/${data?.areaID}/doc/${item}`, {
					responseType: 'blob'
				})
					.then(res => {
						// //console.log(res)
						dt.items.add(new File([res.data], `${item}`));

					})
					.catch(err => err)
			})
		)
		// //console.log(dt.items)
		// //console.log(dt.files)
		var doc_list = dt.files;
		// //console.log(file_list[0] instanceof File);
		setDoc(doc_list);
		setPrewDoc(doc_list);
	}

	//Добавить спектакль
	const add = (form) => {
		form.text = redactor;

		//console.log(form)
		AreaAPI.addArea(form)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Изменить спектакль
	const update = (form) => {
		form.text = redactor;

		//console.log(form)
		AreaAPI.updateArea(id, form)
			.then(result => {

				if (result.status === 200) {
					// //console.log(result);
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Удалить сотрудника
	const deletePerformance = () => {
		// //console.log('Delete')
		AreaAPI.deleteArea(id)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Отрисовка формы
	const renderForm = () => {
		return (
			<>
				<form
					className={s.form}
					onSubmit={
						g1 ? handleSubmit(update) : handleSubmit(add)
					}>

					<Controller
						name="name"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								label="Название*"
								size="small"
								fullWidth
								multiline
								rows={1}
								error={errors?.name?.message && true}
								helperText={errors?.name?.message ? errors?.name?.message : ' '} />}
						rules={{
							required: 'Поле обязательно для заполнения',
							maxLength: { value: 500, message: 'Максимум 500 символов' },
							pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Название не может содержать спецсимволов' }
						}}

						control={control}
						defaultValue={data?.name ? data?.name : ''}
					/>

					<SubTitle>Фото:</SubTitle>

					<InputFileUI file={loadingFiles === true && prew} setFile={setFile} multiple />


					<SubTitle>Райдер:</SubTitle>

					<InputDocFileUI file={loadingFiles === true && prewDoc} setFile={setDoc} multiple />

					<Controller
						name="parking"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								label={"Карта с парковками"}
								size="small"
								multiline
								rows={5}
								fullWidth
								error={errors?.parking?.message && true}
								helperText={errors?.parking?.message ? errors?.parking?.message : ' '} />}
						rules={{
							maxLength: { value: 1000, message: 'Максимум 1000 символов' },
						}}
						control={control}
						defaultValue={data?.parking ? data?.parking : ''}
					/>

					<Controller
						name="description"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								label={"Ссылка на карту*"}
								size="small"
								multiline
								rows={5}
								fullWidth
								error={errors?.description?.message && true}
								helperText={errors?.description?.message ? errors?.description?.message : ' '} />}
						rules={{
							maxLength: { value: 1000, message: 'Максимум 1000 символов' },
						}}
						control={control}
						defaultValue={data?.description ? data?.description : ''}
					/>

					<div className={s.textEditor}>
						<TextEditor redactor={redactor} setRedactor={setRedactor} placeholder='Содержание страницы площадки...' />
					</div>

					<Controller
						name="address"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								label="Адрес"
								size="small"
								fullWidth
								multiline
								rows={2}
								error={errors?.address?.message && true}
								helperText={errors?.address?.message ? errors?.address?.message : ' '} />}
						rules={{
							pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Название не может содержать спецсимволов' }
						}}
						control={control}
						defaultValue={data?.address ? data?.address : ''}
					/>

					<Controller
						name="parkingInfo"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								label="Описание парковки"
								size="small"
								fullWidth
								multiline
								rows={3}
								error={errors?.parkingInfo?.message && true}
								helperText={errors?.parkingInfo?.message ? errors?.parkingInfo?.message : ' '} />}
						rules={{
							pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Название не может содержать спецсимволов' }
						}}
						control={control}
						defaultValue={data?.parkingInfo ? data?.parkingInfo : ''}
					/>

					<div className={s.button}>
						<Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => navigate(-1)}>
							<>Назад</>
						</Button>

						{g1 !== undefined && <Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => deletePerformance(id)}>Удалить</Button>}
						<Button sx={{ borderRadius: '0px' }} variant="outlined" type='submit'>
							{g1 !== undefined ? <>Изменить</> : <>Создать</>}
						</Button>
					</div>

				</form>
			</>
		)
	}

	return (
		<div className={s.container}>
			{loading === true
				?
				renderForm()
				:
				<CircularProgress />
			}
		</div>
	);
}

export default AreaForm;
