import React, { useState, useEffect } from 'react';
import s from './ShowListPushkinSite.module.scss'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import { format } from 'date-fns'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PerformanceAPI from '../../../utils/PerformanceAPI'
import TicketlandAPI from '../../../utils/TicketlandAPI';
import Fade from 'react-reveal/Fade';
import moment from 'moment';
import logo from './img/logo.jpg'


const ShowListPushkinSite = ({ id }) => {
	//console.log(id)

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	// useEffect(() => {
	// 	(loading === false && id === 'undefind') ?
	// 		PerformanceAPI.getPerformancePushkin()
	// 			.then(result => {
	// 				// //console.log(result);
	// 				if (result.status === 200) {
	// 					setData(result.data)
	// 				}

	// 				if (result.response?.status === 406) {
	// 					// //console.log(result.response.data)
	// 					setData([])
	// 				}

	// 				if (result.response?.status === 500) {
	// 					// //console.log(result.response.data)
	// 				}
	// 			})
	// 			:
	// 			PerformanceAPI.getFourPerformance(id)
	// 			.then(result => {
	// 				// //console.log(result);
	// 				if (result.status === 200) {
	// 					setData(result.data)
	// 				}

	// 				if (result.response?.status === 406) {
	// 					// //console.log(result.response.data)
	// 					setData([])
	// 				}

	// 				if (result.response?.status === 500) {
	// 					// //console.log(result.response.data)
	// 				}
	// 			})
	// }, [loading, id]);

	useEffect(() => {
		loading === false &&
			PerformanceAPI.getPerformancePushkin()
				.then(result => {
					// //console.log(result);
					if (result.status === 200) {
						setData(result.data)
					}

					if (result.response?.status === 406) {
						// //console.log(result.response.data)
						setData([])
					}

					if (result.response?.status === 500) {
						// //console.log(result.response.data)
					}
				})

	}, [loading]);

	const [open, setOpen] = useState(false);
	const [url, setUrl] = useState('');

	const handleOpen = (url) => {
		setUrl(url);
		// setOpen(true);
		window.TLIframe.showPopup({url: url})
	}
	const handleClose = () => {
		setUrl('');
		setOpen(false);
	}

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		height: '80%',
		bgcolor: 'background.paper',
		padding: '20px 5px'
	};

	const imgPath = "https://teatrcdr.ru/teatr-api/img/performance/"
	// const [imgArray, setImgArray] = useState(data.picture.split('\n'));

	const sortData = data.sort((prev, next) => {
		return new Date(prev.sort) - new Date(next.sort)
	})

	const sliceData = data.slice(0, 4);


	//console.log(sliceData)
	//console.log(data)

	return (

		<div className={s.showList}>
			{(id ? sliceData : data).map((show) => {
				const str = show.description.slice(0, 200) + '...';


				return (
					<Fade big key={show?.showID}>
						<Card elevation={3} sx={{ height: '100%', position: 'relative', borderRadius: '0', '&:hover .MuiCardMedia-root, &:focus .MuiCardMedia-root': { transform: 'scale(1.1) ', transition: "all 1.1s ease" } }} >

							{/* <CardMedia
								sx={{ height: { xs: '50vw', sm: '33vw', md: '18vw', lg: '20vw' }, overflow: 'hidden !important' }}
								image={imgPath + show.showID + '/' + show.picture[0]}
							/> */}
							<div className={s.carouselItem}>
								<img className={s.carouselImg} src={show.picture[0] ? imgPath + show.showID + '/' + show.picture[0] : logo} alt={show.showID} />
							</div>

							<CardContent sx={{ minHeight: '180px', height: '100%', backgroundColor: 'white', zIndex: 10, position: 'relative' }}>
								<Link to={`/spektakli/${show?.showID}`} >
									<div className={s.showTitle}>
										{show.name}
									</div>
								</Link>
								{show.events.length !== 0 &&
									<>
										<div className={s.eventTitle}>Ближайшие:</div>
										<div className={s.events}>
											{show.events.map(event => {
												return <div className={s.eventStyle} onClick={() => handleOpen(event.url)}>
													{moment(event.date).format('DD.MM.yyyy HH:mm')}
													{event.transfer !== '0000-00-00 00:00:00' && <div className={s.transfer}>
														{moment(event.transfer).format('Перенос с DD.MM.YY')}
													</div>}
												</div>
											})}
										</div>
									</>

								}

								<div className={s.place}>
									<span className={s.placeTitle}>Площадка:</span> <span className={s.placeName}>{show.area}</span>
								</div>
								{/* <div className={s.place}>
									<span className={s.placeTitle}>{show.director.length > 1 ? <>Режиссеры: </> : <>Режиссёр: </>}</span>
									<span className={s.placeName}>
										{show.director.map((name) => {
											return <div key={name.empID} style={{ display: 'inline-block', marginRight: "5px" }}>{name.fio}</div>
										})}
									</span>
								</div> */}
								{/* <Typography variant="body2" color="text.secondary">
									{str}
								</Typography> */}
								{/* <div dangerouslySetInnerHTML={{ __html: show.description.slice(0, 300) + '...' }}></div> */}
								<div dangerouslySetInnerHTML={{ __html: show.description.length>=300 ? show.description.slice(0, 300) + '...' : show.description}}></div>
							</CardContent>
						</Card>
					</Fade>
				)
			})
			}

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>

				<Box sx={style}>
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: -12,
							top: -40,
							color: (theme) => theme.palette.grey[400],
						}}
					>
						<CloseIcon />
					</IconButton>
					<iframe src={url} title='ticketland' height='100%' width='100%' />


				</Box>
			</Modal>
		</div >


	);
}

export default ShowListPushkinSite;
