import React, { useEffect, useState } from 'react';
import s from "./ShowForm.module.scss";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { nanoid } from 'nanoid'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import InputFileUI from '../../../../ui/InputFileUI/InputFileUI';
import SubTitle from './../../../../ui/SubTitle/SubTitle';
import CircularProgress from '@mui/material/CircularProgress';
import EmployeesAPI from '../../../../utils/EmployeesAPI';
import PerformanceAPI from '../../../../utils/PerformanceAPI';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import axios from 'axios'
import TextEditor from '../../../../ui/TextEditor/TextEditor';
import moment from 'moment';

const inflection = require('inflection')


const ShowForm = ({ g1 }) => {

	const [data, setData] = useState([]);
	const [file, setFile] = useState([]);
	const [loadingFiles, setloadingFiles] = useState(false);
	const [imgArray, setImgArray] = useState([]);
	const [prew, setPrew] = useState([]);
	const [loading, setLoading] = useState(false);
	const [actor, setActor] = useState([]);
	const [allTeam, setAllTeam] = useState([]);
	const [redactor, setRedactor] = useState();
	const [description, setDescription] = useState();
	const { id } = useParams()
	const navigate = useNavigate();
	const { control, handleSubmit, formState: { errors }, setValue } = useForm(
		{
			mode: "onSubmit"
		}
	);
	const [actorArray, setActorArray] = useState([]);
	const [firstActor, setFirstActor] = useState('');
	const [secondActor, setSecondActor] = useState('');
	const [randomActor, setRandomActor] = useState('');

	//console.log(actorArray)

	useEffect(() => {
		(loading === true && g1) && (urlToObject())
		setloadingFiles(true)
	}, [loading]);

	useEffect(() => {
		g1 &&
			PerformanceAPI.getPerformanceForId(id)
				.then(result => {
					// //console.log(result.data[0]);
					if (result.status === 200) {
						setData(result.data[0])
						setImgArray(result.data[0]?.picture.split('\n'))
						setRedactor(result.data[0].additionally)
						setDescription(result.data[0].description)
						setLoading(true)

					}

					if (result.response?.status === 500) {
						// //console.log(result.response.data)
					}
				})

		g1 === undefined && setLoading(true)

	}, [g1]);

	useEffect(() => {
		setValue('showID', nanoid());
		setValue('picture[]', file);
	}, [setValue, file, data, loading]);

	useEffect(() => {
		loading === true &&
			EmployeesAPI.getEmployeeDirector(' ')
				.then(result => {
					// //console.log(result);
					if (result.status === 200) {
						setAllTeam(result.data)
					}

					if (result.response?.status === 500) {
						// //console.log(result.response.data)
					}
				})

	}, [loading]);

	useEffect(() => {
		loading === true &&
			EmployeesAPI.getEmployee('artists')
				.then(result => {
					// //console.log(result);
					if (result.status === 200) {
						setActor(result.data)
					}

					if (result.response?.status === 500) {
						// //console.log(result.response.data)
					}
				})

	}, [loading]);

	//Загрузка фото соответствующего сотрудника
	const urlToObject = () => {
		const dt = new DataTransfer();

		const dwnFile = (item) => {
			axios.get(`https://teatrcdr.ru/teatr-api/img/performance/${data?.showID}/${item}`, {
				responseType: 'blob',
				onDownloadProgress: function (progressEvent) {
					let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
					// console.log(percentCompleted)
				},
			})
				.then(res => {
					dt.items.add(new File([res.data], `https://teatrcdr.ru/teatr-api/img/performance/${data?.showID}/${item}`, { type: res.headers['content-type'] }));
					// console.log(dt.files)
					setFile(dt.files);
					// setPrew(dt.files);
				})
		}

		for (var i = 0; i < imgArray.length - 1; i++) {
			dwnFile(imgArray[i]);
		}

		setTimeout(() => {
			setloadingFiles(true);
		}, 500);

	}

	//Добавить спектакль
	const add = (form) => {
		// //console.log(form.actor)
		// form.director = form.director.join()
		// form.author = form.author.join()
		// form.artist = form.artist.join()
		// form.composer = form.composer.join()
		// form.lightingArtist = form.lightingArtist.join()
		// form.soundDesigner = form.soundDesigner.join()
		// form.choreographer = form.choreographer.join()
		// form.photographer = form.photographer.join()
		// form.actor = form.actor.join()
		form.pushkin = form.pushkin === true ? 1 : 0;
		form.visible = form.visible === true ? 1 : 0;
		form.archive = form.archive === true ? 1 : 0;
		form.additionally = redactor;
		form.description = description;
		form.archiveDate = form.archive === 1 ? moment().format('YYYY-MM-DD HH:mm:ss') : '0000-00-00 00:00:00';

		//console.log(form)
		PerformanceAPI.addPerformance(form)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Изменить спектакль
	const update = (form) => {
		// //console.log(form.actor)
		// form.director = form.director.join()
		// form.author = form.author.join()
		// form.artist = form.artist.join()
		// form.composer = form.composer.join()
		// form.lightingArtist = form.lightingArtist.join()
		// form.soundDesigner = form.soundDesigner.join()
		// form.choreographer = form.choreographer.join()
		// form.photographer = form.photographer.join()
		// form.actor = form.actor.join()
		form.pushkin = form.pushkin === true ? 1 : 0;
		form.visible = form.visible === true ? 1 : 0;
		form.archive = form.archive === true ? 1 : 0;
		form.additionally = redactor;
		form.description = description;
		form.archiveDate = form.archive === 1 ? moment().format('YYYY-MM-DD HH:mm:ss') : '0000-00-00 00:00:00';

		//console.log(form)
		PerformanceAPI.updatePerformance(id, form)
			.then(result => {

				if (result.status === 200) {
					// //console.log(result);
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Удалить сотрудника
	const deletePerformance = () => {
		// //console.log('Delete')
		PerformanceAPI.deletePerformance(id)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	// // //console.log(errors)

	const stepOne = () => {
		return (
			<div className={s.step}>

				<Controller
					name="name"
					render={({ field }) => <TextField {...field} variant="outlined" label="Название*" size="small" fullWidth error={errors?.name?.message && true} helperText={errors?.name?.message ? errors?.name?.message : ' '} />}
					rules={{
						required: 'Поле обязательно для заполнения',
						pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Название не может содержать спецсимволов' }
					}}
					control={control}
					defaultValue={data?.name ? inflection.titleize(data?.name) : ''}
				/>

				<Grid container spacing={1}>
					<Grid xs={12} md={4}>
						<Controller
							name="pushkin"
							render={({ field }) => <FormControlLabel sx={{ mb: '5px' }} required control={<Switch
								onChange={(e) => field.onChange(e.target.checked)}
								checked={field.value}
							/>} label="Пушкинская карта" />}
							rules={{
								// required: 'Поле обязательно для заполнения',
								pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Название не может содержать спецсимволов' }
							}}
							control={control}
							defaultValue={data?.pushkin === '1' ? true : false}
						/>
					</Grid>
					<Grid xs={12} md={4}>
						<Controller
							name="visible"
							render={({ field }) => <FormControlLabel sx={{ mb: '5px' }} required control={<Switch
								onChange={(e) => field.onChange(e.target.checked)}
								checked={field.value}
							/>} label="Опубликовать" />}
							rules={{
								// required: 'Поле обязательно для заполнения',
								pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Название не может содержать спецсимволов' }
							}}
							control={control}
							defaultValue={data?.visible === '1' ? true : false}
						/>
					</Grid>
					<Grid xs={12} md={4}>
						<Controller
							name="archive"
							render={({ field }) => <FormControlLabel sx={{ mb: '5px' }} required control={<Switch
								onChange={(e) => field.onChange(e.target.checked)}
								checked={field.value}
							/>} label="Архивировать" />}
							rules={{
								// required: 'Поле обязательно для заполнения',
								pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Название не может содержать спецсимволов' }
							}}
							control={control}
							defaultValue={data?.archive === '1' ? true : false}
						/>
					</Grid>
					<Grid xs={12} md={8}>
						{/* <Controller
							name="duration"
							render={({ field }) => <TextField {...field} variant="outlined" label="Продолжительность*" size="small" fullWidth error={errors?.duration?.message && true} helperText={errors?.duration?.message ? errors?.duration?.message : ' '} />}
							rules={{
								required: 'Поле обязательно для заполнения',
								pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Название не может содержать спецсимволов' }
							}}
							control={control}
							defaultValue={data?.duration ? data?.duration : ''}
						/> */}

					</Grid>

				</Grid>

				<SubTitle>Краткое описание:</SubTitle>

				<div className={s.textEditor}>
					<TextEditor redactor={description} setRedactor={setDescription} placeholder='Краткая информация по спектаклю' />
				</div>

				{/* <Controller
					name="description"
					render={({ field }) =>
						<TextField
							{...field}
							variant="outlined"
							label={"Краткое описание*"}
							size="small"
							multiline
							rows={4}
							fullWidth
							error={errors?.description?.message && true}
							helperText={errors?.description?.message ? errors?.description?.message : ' '} />}
					rules={{
						required: 'Поле обязательно для заполнения',
						pattern: { value: /[^@#$%^&*()_]+$/, message: 'Имя пользователя не может содержать спецсимволов' }
					}}
					control={control}
					defaultValue={data?.description ? data?.description : ''}
				/> */}

				<SubTitle>Фото:</SubTitle>

				{/* <InputFileUI file={file} setFile={setFile} multiple /> */}
				{
					loadingFiles === true ? <InputFileUI file={loadingFiles === true && file} setFile={setFile} multiple /> : <CircularProgress />
				}

				<SubTitle>Описание:</SubTitle>

				<div className={s.textEditor}>
					<TextEditor redactor={redactor} setRedactor={setRedactor} placeholder='Описание спектакля' />
				</div>



			</div>
		)
	}

	const stepTwo = () => {
		return (
			<div className={s.step}>
				<Controller
					name="age"
					render={({ field }) =>
						<FormControl sx={{ marginBottom: '20px' }}>
							<FormLabel id="demo-radio-buttons-group-label">Возрастное ограничение</FormLabel>
							<RadioGroup
								row
								aria-labelledby="demo-radio-buttons-group-label"
								name="radio-buttons-group"
								{...field}
							>
								<FormControlLabel value="0+" control={<Radio />} label="0+" />
								<FormControlLabel value="6+" control={<Radio />} label="6+" />
								<FormControlLabel value="8+" control={<Radio />} label="8+" />
								<FormControlLabel value="12+" control={<Radio />} label="12+" />
								<FormControlLabel value="16+" control={<Radio />} label="16+" />
								<FormControlLabel value="18+" control={<Radio />} label="18+" />
							</RadioGroup>
						</FormControl>
					}
					rules={{
						required: 'Поле обязательно для заполнения',
						pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Название не может содержать спецсимволов' }
					}}
					control={control}
					defaultValue={data?.age ? inflection.titleize(data?.age) : '12+'}
				/>

				<Controller
					name="area"
					control={control}
					defaultValue={data?.area ? data?.area : 'поварская'}
					render={({ field }) =>
						<FormControl fullWidth error={errors?.area?.message ? true : false}>
							<InputLabel id="demo-simple-select-label">Площадка</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								{...field}
								label="Площадка"
							>
								<MenuItem value='поварская'>Поварская</MenuItem>
								<MenuItem value='беговая'>Беговая</MenuItem>
								<MenuItem value='сокол'>Сокол</MenuItem>

							</Select>
							<FormHelperText>{errors?.area?.message ? errors?.area?.message : ' '}</FormHelperText>
						</FormControl>
					}
					rules={{
						required: 'Поле обязательно для заполнения',
					}}
				/>

				{/* <Controller
					name="director"
					control={control}
					defaultValue={data?.director ? data?.director.split(',') : []}
					render={({ field }) =>
						<FormControl fullWidth error={errors?.director?.message ? true : false}>
							<InputLabel>Режиссер*</InputLabel>
							<Select

								{...field}
								label="Режиссер*"
								multiple
							>
								{allTeam.map(((item) => {
									return <MenuItem key={item?.empID} value={item?.empID}>{item?.fio}</MenuItem>
								}))}

							</Select>
							<FormHelperText>{errors?.director?.message ? errors?.director?.message : ' '}</FormHelperText>
						</FormControl>
					}
					rules={{
						required: 'Поле обязательно для заполнения',
					}}
				/> */}

				{/* <Controller
					name="author"
					control={control}
					defaultValue={data?.author ? data?.author.split(',') : []}
					render={({ field }) =>
						<FormControl fullWidth error={errors?.author?.message ? true : false}>
							<InputLabel id="demo-simple-select-label">Автор</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								{...field}
								label="Автор"
								multiple
							>
								{allTeam?.map(((item) => {
									return <MenuItem key={item?.empID} value={item?.empID}>{item?.fio}</MenuItem>
								}))}

							</Select>
							<FormHelperText>{errors?.author?.message ? errors?.author?.message : ' '}</FormHelperText>
						</FormControl>
					}
				/> */}

				{/* <Controller
					name="artist"
					control={control}
					defaultValue={data?.artist ? data?.artist.split(',') : []}
					render={({ field }) =>
						<FormControl fullWidth error={errors?.artist?.message ? true : false}>
							<InputLabel id="demo-simple-select-label">Художник</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								{...field}
								label="Художник"
								multiple
							>
								{allTeam?.map(((item) => {
									return <MenuItem key={item?.empID} value={item?.empID}>{item?.fio}</MenuItem>
								}))}

							</Select>
							<FormHelperText>{errors?.artist?.message ? errors?.artist?.message : ' '}</FormHelperText>
						</FormControl>
					}
				/> */}

			</div>
		)
	}

	const stepThree = () => {
		return (
			<div className={s.step}>

				{/* <Controller
					name="composer"
					control={control}
					defaultValue={data?.composer ? data?.composer.split(',') : []}
					render={({ field }) =>
						<FormControl fullWidth error={errors?.composer?.message ? true : false}>
							<InputLabel id="demo-simple-select-label">Композитор</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								{...field}
								label="Композитор"
								multiple
							>
								{allTeam.length !== 0 && allTeam?.map(((item) => {
									return <MenuItem key={item?.empID} value={item?.empID}>{item?.fio}</MenuItem>
								}))}

							</Select>
							<FormHelperText>{errors?.composer?.message ? errors?.composer?.message : ' '}</FormHelperText>
						</FormControl>
					}
				/>

				<Controller
					name="lightingArtist"
					control={control}
					defaultValue={data?.lightingArtist ? data?.lightingArtist.split(',') : []}
					render={({ field }) =>
						<FormControl fullWidth error={errors?.lightingArtist?.message ? true : false}>
							<InputLabel id="demo-simple-select-label">Художник по свету</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								{...field}
								label="Художник по свету"
								multiple
							>
								{allTeam.length !== 0 && allTeam?.map(((item) => {
									return <MenuItem key={item?.empID} value={item?.empID}>{item?.fio}</MenuItem>
								}))}

							</Select>
							<FormHelperText>{errors?.lightingArtist?.message ? errors?.lightingArtist?.message : ' '}</FormHelperText>
						</FormControl>
					}
				/>

				<Controller
					name="videoArtist"
					control={control}
					defaultValue={data?.videoArtist ? data?.videoArtist.split(',') : []}
					render={({ field }) =>
						<FormControl fullWidth error={errors?.videoArtist?.message ? true : false}>
							<InputLabel id="demo-simple-select-label">Видеохудожник</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								{...field}
								label="Видеохудожник"
								multiple
							>
								{allTeam.length !== 0 && allTeam?.map(((item) => {
									return <MenuItem key={item?.empID} value={item?.empID}>{item?.fio}</MenuItem>
								}))}

							</Select>
							<FormHelperText>{errors?.videoArtist?.message ? errors?.videoArtist?.message : ' '}</FormHelperText>
						</FormControl>
					}
				/>

				<Controller
					name="soundDesigner"
					control={control}
					defaultValue={data?.soundDesigner ? data?.soundDesigner.split(',') : []}
					render={({ field }) =>
						<FormControl fullWidth error={errors?.soundDesigner?.message ? true : false}>
							<InputLabel id="demo-simple-select-label">Звукорежессер</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								{...field}
								label="Звукорежессер"
								multiple
							>
								{allTeam.length !== 0 && allTeam?.map(((item) => {
									return <MenuItem key={item?.empID} value={item?.empID}>{item?.fio}</MenuItem>
								}))}

							</Select>
							<FormHelperText>{errors?.soundDesigner?.message ? errors?.soundDesigner?.message : ' '}</FormHelperText>
						</FormControl>
					}
				/>

				<Controller
					name="choreographer"
					control={control}
					defaultValue={data?.choreographer ? data?.choreographer.split(',') : []}
					render={({ field }) =>
						<FormControl fullWidth error={errors?.choreographer?.message ? true : false}>
							<InputLabel id="demo-simple-select-label">Хореограф</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								{...field}
								label="Хореограф"
								multiple
							>
								{allTeam.length !== 0 && allTeam?.map(((item) => {
									return <MenuItem key={item?.empID} value={item?.empID}>{item?.fio}</MenuItem>
								}))}

							</Select>
							<FormHelperText>{errors?.choreographer?.message ? errors?.choreographer?.message : ' '}</FormHelperText>
						</FormControl>
					}
				/>

				<Controller
					name="photographer"
					control={control}
					defaultValue={data?.photographer ? data?.photographer.split(',') : []}
					render={({ field }) =>
						<FormControl fullWidth error={errors?.photographer?.message ? true : false}>
							<InputLabel id="demo-simple-select-label">Фотограф</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								{...field}
								label="Фотограф"
								multiple
							>
								{allTeam.length !== 0 && allTeam?.map(((item) => {
									return <MenuItem key={item?.empID} value={item?.empID}>{item?.fio}</MenuItem>
								}))}

							</Select>
							<FormHelperText>{errors?.photographer?.message ? errors?.photographer?.message : ' '}</FormHelperText>
						</FormControl>
					}
				/> */}

				{/* <Controller
					name="actor"
					control={control}
					defaultValue={data?.actor ? data?.actor.split(',') : []}
					render={({ field }) =>
						<FormControl fullWidth error={errors?.actor?.message ? true : false}>
							<InputLabel id="demo-simple-select-label">Исполнители</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								{...field}
								// label="Актеры"
								multiple
								input={<OutlinedInput id="select-multiple-chip" label="Исполнители" />}
								renderValue={(selected) => (
									<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
										{selected.map((value) => (
											actor.map((item) => {
												if (item.empID === value) {
													return <Chip key={value} label={item.fio} />
												}

											})

										))}
									</Box>
								)}
							>
								{actor.length !== 0 && actor?.map(((item) => {
									return <MenuItem key={item?.empID} value={item?.empID}>{item?.fio}</MenuItem>
								}))}

							</Select>
							<FormHelperText>{errors?.actor?.message ? errors?.actor?.message : ' '}</FormHelperText>
						</FormControl>
					}
				/> */}
				{/* <SubTitle>Исполнители:</SubTitle>
				<div className={s.formGroupActor}>

					<FormControl fullWidth>
						<InputLabel id="firstActor">Исполнитель</InputLabel>
						<Select

							labelId="firstActor"
							id="firstActor"
							onChange={handleChangeOne}
							input={<OutlinedInput id="select-multiple-chip" label="Исполнитель" />}
							value={firstActor}
							renderValue={(selected) => (
								<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
									{actor.map((item) => {
										if (item.empID === selected) {
											return <Chip sx={{ height: '23px' }} key={selected} label={item.fio} />
										}

									})}
								</Box>
							)}
						>
							{actor.length !== 0 && actor?.map(((item) => {
								return <MenuItem key={item?.empID} value={item?.empID}>{item?.fio}</MenuItem>
							}))}

						</Select>
					</FormControl>

					<FormControl fullWidth>
						<InputLabel id="secondActor">В составе из списка</InputLabel>
						<Select

							labelId="secondActor"
							id="secondActor"
							onChange={handleChangeTwo}
							input={<OutlinedInput id="select-multiple-chip" label="В составе из списка" />}
							value={secondActor}
							renderValue={(selected) => (
								<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
									{
										actor.map((item) => {
											if (item.empID === selected) {
												return <Chip sx={{ height: '23px' }} key={selected} label={item.fio} />
											}
										})
									}
								</Box>
							)}
						>
							{actor.length !== 0 && actor?.map(((item) => {
								return <MenuItem key={item?.empID} value={item?.empID}>{item?.fio}</MenuItem>
							}))}

						</Select>
					</FormControl>

					<Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={addActor}>
						<>+</>
					</Button>
				</div> */}

				{/* <div className={s.formGroupActor}>

					<FormControl fullWidth sx={{ opacity: '0' }}>
						<InputLabel id="firstActor">Исполнитель</InputLabel>
						<Select
							disabled
							labelId="firstActor"
							id="firstActor"
							onChange={handleChangeOne}
							input={<OutlinedInput id="select-multiple-chip" label="Исполнитель" />}
							value={firstActor}
							renderValue={(selected) => (
								<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
									{actor.map((item) => {
										if (item.empID === selected) {
											return <Chip sx={{ height: '23px' }} key={selected} label={item.fio} />
										}

									})}
								</Box>
							)}
						>
							{actor.length !== 0 && actor?.map(((item) => {
								return <MenuItem key={item?.empID} value={item?.empID}>{item?.fio}</MenuItem>
							}))}

						</Select>
					</FormControl>

					<FormControl fullWidth>
						<TextField  
						value={randomActor}
						variant="outlined" 
						label="В составе произвольно" 
						onChange={handleChangeRandom}
						fullWidth  />
					</FormControl>

					<Button sx={{ borderRadius: '0px', opacity: '0', cursor: 'default' }} variant="outlined">
						<>+</>
					</Button>
				</div> */}

				{/* <div className={s.prewActor}>
					{
						actorArray.map((item, key) => {
							//console.log(key)
							if (item.length === 2) {
								return <div key={key} className={s.actorItem}>
									{actor.map((info) => {
										if (info.empID === item[0]) {
											return <span key={info.empID}>{info.fio}</span>
										}
									})} / {actor.map((info) => {
										if (info.empID === item[1]) {
											return <span key={info.empID}>{info.fio}</span>
										}
									})}
								</div>
							} else if (item.length === 1) {
								return <div key={key} className={s.actorItem}>
									{actor.map((info) => {
										if (info.empID === item[0]) {
											return <span key={info.empID}>{info.fio}</span>
										}
									})}
								</div>
							}
						})
					}
				</div> */}


			</div>
		)
	}

	// const handleChangeOne = (event) => {
	// 	// //console.log(event.target.value)
	// 	setFirstActor(event.target.value)

	// };

	// const handleChangeTwo = (event) => {
	// 	// //console.log(event.target.value)
	// 	setSecondActor(event.target.value)
	// };

	// const handleChangeRandom = (event) => {
	// 	// //console.log(event.target.value)
	// 	setRandomActor(event.target.value)
	// };

	// const addActor = () => {
	// 	if (firstActor !== '' && secondActor !== '' && randomActor === '') {
	// 		//console.log(idActor(firstActor))
	// 		const idActor = (id) => {
	// 			{actor.map((info) => {
	// 				if (info.empID === id) {
	// 					return info.fio
	// 				}
	// 			})}
	// 		}


	// 		const id1=idActor(firstActor)
	// 		const id2=idActor(secondActor)

	// 		setActorArray([...actorArray, [[id1, firstActor],[id2, secondActor] ]])
	// 		setFirstActor('')
	// 		setSecondActor('')
	// 	} else if (firstActor !== '' && secondActor === '' && randomActor === '') {
	// 		setActorArray([...actorArray, [firstActor]])
	// 		setFirstActor('')
	// 		setSecondActor('')
	// 	} else if (firstActor === '' && secondActor !== '' && randomActor === '') {
	// 		setActorArray([...actorArray, [secondActor]])
	// 		setFirstActor('')
	// 		setSecondActor('')
	// 	} else if (firstActor !== '' && secondActor === '' && randomActor !== '') {
	// 		setActorArray([...actorArray, [secondActor]])
	// 		setFirstActor('')
	// 		setSecondActor('')
	// 		setRandomActor('')
	// 	}
	// }

	const stepFour = () => {
		return (
			<div className={s.step}>





				{/* <Controller
					name="additionally"
					render={({ field }) =>
						<TextField
							{...field}
							variant="outlined"
							label={"Дополнительно"}
							size="small"
							multiline
							rows={7}
							fullWidth
							error={errors?.additionally?.message && true}
							helperText={errors?.additionally?.message ? errors?.additionally?.message : ' '} />}
					rules={{
						pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Имя пользователя не может содержать спецсимволов' }
					}}
					control={control}
					defaultValue={data?.additionally ? inflection.titleize(data?.additionally) : ''}
				/> */}

				<div className={s.button}>
					<Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => navigate(-1)}>
						<>Назад</>
					</Button>

					{g1 !== undefined && <Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => deletePerformance(id)}>Удалить</Button>}
					<Button sx={{ borderRadius: '0px' }} variant="outlined" type='submit'>
						{g1 !== undefined ? <>Изменить</> : <>Создать</>}
					</Button>
				</div>
			</div>
		)
	}

	//Отрисовка формы
	const renderForm = () => {
		return (
			<>
				<form
					className={s.form}
					onSubmit={
						g1 ? handleSubmit(update) : handleSubmit(add)
					}>

					{/* <SubTitle>Описание:</SubTitle> */}

					{stepOne()}

					{stepTwo()}

					{stepThree()}

					{stepFour()}


					{/* <div className={s.button}>
						<Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => setStep(1)}>
							<>В начало</>
						</Button>

						
					</div> */}

				</form>
			</>
		)
	}

	return (
		<div className={s.container}>
			{loading === true
				?
				renderForm()
				:
				<CircularProgress />
			}
		</div>
	);
}

export default ShowForm;
