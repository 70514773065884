import React from 'react';
import s from "./TicketPage.module.scss"
import { Link, Routes, Route } from "react-router-dom";
import MainTitle from '../../../ui/MainTitle/MainTitle';
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';
import TicketForm from '../../../components/Admin/Ticket/TicketForm/TicketForm';

const TicketPage = () => {

	return (
		<>
			<Routes>
				<Route index element={<HelmetUI title="Купить билеты" description="Управление страницей купить билеты" robots="none" />} />
			</Routes>

			<Routes>
				<Route index element={<MainTitle>Купить билеты</MainTitle>} />
			</Routes>

			<div className={s.content}>
				<Routes>
					<Route index element={<TicketForm g1={true}/>} />
				</Routes>
			</div>
		</>
	);
}

export default TicketPage;
