import React, { useState, useEffect } from 'react';
import s from "./VaсanciesPage.module.scss"
import { Link, Routes, Route } from "react-router-dom";
import MainTitle from '../../../ui/MainTitle/MainTitle';
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';
import VacanciesForm from '../../../components/Admin/Vacancies/VacanciesForm/VacanciesForm';

const VaсanciesPage = () => {

	return (
		<>
			<Routes>
				<Route index element={<HelmetUI title="Вакансии" description="Управление событиями театра" robots="none" />} />
			</Routes>

			<Routes>
				<Route index element={<MainTitle>Вакансии</MainTitle>} />
			</Routes>

			<div className={s.content}>
				<Routes>
					<Route index element={<VacanciesForm g1={true}/>} />
				</Routes>
			</div>
		</>
	);
}

export default VaсanciesPage;
