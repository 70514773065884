import React from 'react';
import s from "./ContactPage.module.scss"
import { Link, Routes, Route } from "react-router-dom";
import MainTitle from '../../../ui/MainTitle/MainTitle';
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';
import ContactForm from '../../../components/Admin/Contact/ContactForm/ContactForm';

const ContactPage = () => {

	return (
		<>
			<Routes>
				<Route index element={<HelmetUI title="Контакты" description="Управление страницей официальная информация" robots="none" />} />
			</Routes>

			<Routes>
				<Route index element={<MainTitle>Контакты</MainTitle>} />
			</Routes>

			<div className={s.content}>
				<Routes>
					<Route index element={<ContactForm g1={true}/>} />
				</Routes>
			</div>
		</>
	);
}

export default ContactPage;
