import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({ redactor, setRedactor, placeholder }) => {

	const toolbarOptions = {
		toolbar: [
			['bold', 'italic', 'underline', 'strike'],
			['blockquote', 'code-block'],
			[{ size: [] }, { 'font': [] }],
			[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
			[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
			['image', 'link', 'video'],
			[{'color':[]}, {'background':[]}],
			['clean']
			
		],
		clipboard: {
			matchVisual: false
		}

	}

	return (

		<ReactQuill theme="snow" modules={toolbarOptions} value={redactor} onChange={setRedactor} placeholder={placeholder} />

	);
}

export default TextEditor;
