import React from 'react';
import s from './ArtistyPageSite.module.scss'
import MainTitle from '../../ui/MainTitle/MainTitle';
import HelmetUI from '../../ui/HelmetUI/HelmetUI';
import { Routes, Route } from "react-router-dom";
import ArtistyList from '../../components/ArtistySite/ArtistyList/ArtistyList';
import ArtistyInformation from '../../components/ArtistySite/ArtistyInformation/ArtistyInformation';

const ArtistyPageSite = () => {
	return (
		<div>
			<div className={s.container}>
				<Routes>
					<Route index element={<HelmetUI title="Артисты - Центр драматургии и режиссуры" description="Театр Центр драматургии и режиссуры" />} />
				</Routes>

				<Routes>
					<Route index element={<MainTitle>Труппа</MainTitle>} />
				</Routes>

				<div className={s.content}>
					<Routes>
						<Route index element={<ArtistyList />} />
						<Route path=":id" element={<ArtistyInformation />} />
					</Routes>
				</div>
			</div>
		</div>
	);
}

export default ArtistyPageSite;
