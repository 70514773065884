import React from 'react';
import s from './LabsPageSite.module.scss'
import MainTitle from '../../ui/MainTitle/MainTitle';
import HelmetUI from '../../ui/HelmetUI/HelmetUI';
import { Link, Routes, Route } from "react-router-dom";
import LabsListSite from '../../components/LabsSite/LabsListSite/LabsListSite';
import LabsInformation from '../../components/LabsSite/LabsInformation/LabsInformation';

const LabsPageSite = () => {

	return (
		<div className={s.container}>
			<Routes>
				<Route index element={<HelmetUI title="Лаборатории - Центр драматургии и режиссуры" description="Театр Центр драматургии и режиссуры" />} />
			</Routes>

			<Routes>
				<Route index element={<MainTitle>Лаборатории</MainTitle>} />
			</Routes>

			<div className={s.content}>
				<Routes>
					<Route index element={<LabsListSite />} />
					<Route path=":id" element={<LabsInformation />} />
				</Routes>
			</div>
		</div>

	);
}

export default LabsPageSite;
