import React, { useEffect, useState } from 'react';
import s from './EmployeesForm.module.scss'
import TextField from '@mui/material/TextField';
import { useForm, Controller } from "react-hook-form";
import Button from '@mui/material/Button';
import { useNavigate, useParams } from "react-router-dom";
import { nanoid } from 'nanoid'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InputFileUI from '../../../../ui/InputFileUI/InputFileUI';
import SubTitle from './../../../../ui/SubTitle/SubTitle';
import EmployeesAPI from '../../../../utils/EmployeesAPI';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import PerformanceAPI from '../../../../utils/PerformanceAPI';
const inflection = require('inflection')



const EmployeesForm = ({ g1 }) => {
	// //console.log(data)


	const [data, setData] = useState([]);
	const [alignment, setAlignment] = useState('artists');
	const [file, setFile] = useState({});
	const [prew, setPrew] = useState({});
	const [imgArray, setImgArray] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingFiles, setloadingFiles] = useState(false);
	const { type, id } = useParams()
	const [show, setShow] = useState([]);
	const navigate = useNavigate();
	const { control, handleSubmit, formState: { errors }, setError, register, setValue, unregister } = useForm(
		{
			mode: "onSubmit"
		}
	);

	const checkData = (checkData) => {
		const result = (typeof checkData === 'object' &&
			!Array.isArray(checkData) &&
			checkData !== null)
			? true
			: false
		return result;
	}

	// //console.log(file)

	useEffect(() => {
		(loading === true && g1) && (urlToObject())
		// setloadingFiles(true)
	}, [loading]);

	// useEffect(() => {
	// 	PerformanceAPI.getPerformanceList()
	// 		.then(result => {
	// 			// //console.log(result.data);
	// 			if (result.status === 200) {
	// 				setShow(result.data)
	// 			}

	// 			if (result.response?.status === 500) {
	// 				// //console.log(result.response.data)
	// 			}
	// 		})
	// }, []);

	useEffect(() => {
		g1 && EmployeesAPI.getEmployeeForId(id, type)
			.then(result => {
				//console.log(result);
				if (result.status === 200) {
					setAlignment(type)
					setData(result.data[0])
					result.data[0]?.picture && setImgArray(result.data[0]?.picture.split('\n'))
				}

				if (result.response?.status === 500) {
					//console.log(result.response.data)
				}
			})
			.then(() => {
				PerformanceAPI.getPerformanceList()
					.then(result => {
						// //console.log(result.data);
						if (result.status === 200) {
							setShow(result.data)
							setLoading(true)
						}

						if (result.response?.status === 500) {
							// //console.log(result.response.data)
						}
					})
			})

		g1 === undefined && setLoading(true)
	}, []);

	useEffect(() => {
		setValue('type', alignment)
		setValue('empID', nanoid())

		alignment === 'artists' &&
			setValue('picture[]', file)
		unregister('email')
		unregister('position')
		unregister('profession')

		alignment === 'team' &&
			unregister('picture')
		unregister('email')
		unregister('position')
		alignment === 'contacts' &&
			unregister('picture')
		unregister('email')
		unregister('position')
		unregister('profession')

	}, [alignment, file, data]);

	//Загрузка фото соответствующего сотрудника
	const urlToObject = async () => {
		const dt = new DataTransfer();

		const dwnFile = (item) => {
			axios.get(`https://teatrcdr.ru/teatr-api/img/employee/${data?.empID}/${item}`, {
				responseType: 'blob',
				onDownloadProgress: function (progressEvent) {
					let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
				},
			})
				.then(res => {
					dt.items.add(new File([res.data], `https://teatrcdr.ru/teatr-api/img/employee/${data?.empID}/${item}`, { type: res.headers['content-type'] }));
					setFile(dt.files);
					// setPrew(dt.files);
				})
				.catch(err => err)
		}

		for (var i = 0; i < imgArray.length - 1; i++) {
			dwnFile(imgArray[i]);
		}

		setTimeout(() => {
			setloadingFiles(true);
		}, 500);

	}

	const handleChange = (event, newAlignment) => {
		if (newAlignment !== null) {
			setAlignment(newAlignment);
		}
	};

	//Добавить сотрудника
	const add = (form) => {
		form.showID = form.showID.join()
		console.log(form)
		EmployeesAPI.addEmployee(form)
			.then(result => {
				//console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					//console.log(result.response.data)
				}
			})
	}

	//Обновить сотрудника
	const update = (form) => {
		form.showID = form.showID.join()
		console.log(form)
		EmployeesAPI.updateEmployee(id, form)
			.then(result => {
				//console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					//console.log(result.response.data)
				}
			})
	}

	//Удалить сотрудника
	const deleteAmployee = () => {
		//console.log('Delete')
		EmployeesAPI.deleteEmployee(id, type)
			.then(result => {
				//console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					//console.log(result.response.data)
				}
			})
	}
	//Форма
	const renderForm = () => {
		return (
			<>
				<form
					className={s.form}
					onSubmit={
						g1 ? handleSubmit(update) : handleSubmit(add)
					}>

					<ToggleButtonGroup
						color="primary"
						value={alignment}
						exclusive
						onChange={handleChange}
						aria-label="Platform"
						sx={{ pb: 2.5 }}
						size='small'
					>
						<ToggleButton disabled={(g1 && alignment !== 'artists') && true} value="artists">Артисты</ToggleButton>
						<ToggleButton disabled={(g1 && alignment !== 'team') && true} value="team">Команда</ToggleButton>
						<ToggleButton disabled={(g1 && alignment !== 'contacts') && true} value="contacts">Контакты</ToggleButton>
					</ToggleButtonGroup>

					{alignment === 'artists' &&
						<>
							<SubTitle>Фото:</SubTitle>
							{/* <InputFileUI file={file} setFile={setFile} multiple /> */}
							{
								loadingFiles === true ? <InputFileUI file={loadingFiles === true && file} setFile={setFile} multiple /> : <CircularProgress />
							}
						</>
					}

					<SubTitle>Описание:</SubTitle>
					<Controller
						name="fio"
						render={({ field }) => <TextField {...field} variant="outlined" label="ФИО" size="small" fullWidth error={errors?.fio?.message && true} helperText={errors?.fio?.message ? errors?.fio?.message : ' '} />}
						rules={{
							// required: 'Поле обязательно для заполнения',
							pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Имя пользователя не может содержать спецсимволов' }
						}}
						control={control}
						defaultValue={data?.fio ? data?.fio : ''}
					/>

					{alignment === 'artists' &&
						<>
							<Controller
								name="troupe"
								control={control}
								defaultValue={data?.troupe ? data?.troupe : '1'}
								render={({ field }) =>
									<FormControl fullWidth error={errors?.troupe?.message ? true : false}>
										<InputLabel id="demo-simple-select-label">Тип</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											{...field}
											label="Тип"
										// defaultValue={data?.profession ? inflection.titleize(data?.profession) : 'director'}
										>
											<MenuItem value='1'>Труппа</MenuItem>
											<MenuItem value='0'>Приглашенные артисты</MenuItem>
										</Select>
										<FormHelperText>{errors?.troupe?.message ? errors?.troupe?.message : ' '}</FormHelperText>
									</FormControl>
								}
								rules={{
									required: 'Поле обязательно для заполнения',
									pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Имя пользователя не может содержать спецсимволов' }
								}}
							/>
						</>
					}



					{alignment === 'team' &&
						<>
							<Controller
								name="profession"
								control={control}
								defaultValue={data?.profession ? data?.profession : 'режиссер'}
								render={({ field }) =>
									<FormControl fullWidth error={errors?.profession?.message ? true : false}>
										<InputLabel id="demo-simple-select-label">Профессия</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											{...field}
											label="Профессия"
										// defaultValue={data?.profession ? inflection.titleize(data?.profession) : 'director'}
										>
											<MenuItem value='режиссер'>Режиссер</MenuItem>
											<MenuItem value='хореограф'>Хореограф</MenuItem>
											<MenuItem value='композитор'>Композитор</MenuItem>
											<MenuItem value='художник'>Художник</MenuItem>
											<MenuItem value='художник по свету'>Художник по свету</MenuItem>
											<MenuItem value='видеохудожник'>Видеохудожник</MenuItem>
											<MenuItem value='звукорежессер'>Звукорежессер</MenuItem>
											<MenuItem value='автор'>Автор</MenuItem>
											<MenuItem value='фотограф'>Фотограф</MenuItem>
										</Select>
										<FormHelperText>{errors?.profession?.message ? errors?.profession?.message : ' '}</FormHelperText>
									</FormControl>
								}
								rules={{
									required: 'Поле обязательно для заполнения',
									pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Имя пользователя не может содержать спецсимволов' }
								}}
							/>
							<Controller
								name="number"
								render={({ field }) => <TextField {...field} variant="outlined" label="Номер в списке" size="small" error={errors?.number?.message && true} helperText={errors?.number?.message ? errors?.number?.message : ' '} />}
								rules={{
									// required: 'Поле обязательно для заполнения',
									pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Имя пользователя не может содержать спецсимволов' }
								}}
								control={control}
								defaultValue={data?.number ? data?.number : 500}
							/>
						</>
					}

					{alignment === 'contacts' &&
						<>
							<Controller
								name="position"
								render={({ field }) =>
									<TextField {...field}
										variant="outlined"
										label="Должность"
										size="small"
										fullWidth
										error={errors?.position?.message && true}
										helperText={errors?.position?.message ? errors?.position?.message : ' '}
									/>
								}
								rules={{
									required: 'Поле обязательно для заполнения',
									pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Имя пользователя не может содержать спецсимволов' }
								}}
								control={control}
								defaultValue={data?.position ? data?.position : ''}
							/>
							<Controller
								name="email"
								render={({ field }) =>
									<TextField {...field}
										variant="outlined"
										label="Email"
										size="small"
										fullWidth
										error={errors?.email?.message && true}
										helperText={errors?.email?.message ? errors?.email?.message : ' '}
									/>
								}
								rules={{
									required: 'Поле обязательно для заполнения',
									pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Имя пользователя не может содержать спецсимволов' }
								}}
								control={control}
								defaultValue={data?.email ? data?.email : ''}
							/>
						</>
					}

					<Controller
						name="description"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								label={alignment === 'contacts' ? "Телефоны" : "Биография"}
								size="small"
								multiline
								rows={10}
								fullWidth
								error={errors?.description?.message && true}
								helperText={errors?.description?.message ? errors?.description?.message : ' '} />}
						rules={{
							pattern: { value: /[^!@#$%^&*_]+$/, message: 'Имя пользователя не может содержать спецсимволов' }
						}}
						control={control}
						defaultValue={data?.description ? data?.description : ''}
					/>

					{(alignment === 'artists' || alignment === 'team') &&
						<>
							<Controller
								name="showID"
								control={control}
								defaultValue={data?.showID ? data?.showID?.split(',') : []}
								render={({ field }) =>
									<FormControl fullWidth error={errors?.showID?.message ? true : false}>
										<InputLabel>Спектакли</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											{...field}
											label={"Спектакль"}
											multiple
										>
											{show?.map(((item) => {
												return <MenuItem key={item?.showID} value={item?.showID}>{item?.name.toUpperCase()}</MenuItem>
											}))}

										</Select>
										<FormHelperText>{errors?.showID?.message ? errors?.showID?.message : ' '}</FormHelperText>
									</FormControl>
								}
							/>
						</>
					}

					<div className={s.button}>

						{g1 !== undefined && <Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => deleteAmployee(id)}>Удалить</Button>}
						<Button sx={{ borderRadius: '0px' }} variant="outlined" type='submit'>
							{g1 !== undefined ? <>Изменить</> : <>Добавить</>}
						</Button>
					</div>

				</form>
			</>
		)
	}

	return (
		<div className={s.container}>
			{loading === true
				?
				renderForm()
				:
				<div className={s.circular}>
					<CircularProgress />
				</div>

			}
		</div>
	);
}



export default EmployeesForm;
