import React, { useState, useEffect } from 'react';
import s from './ArtistyList.module.scss'
import EmployeesAPI from './../../../utils/EmployeesAPI';
import Grid from '@mui/material/Unstable_Grid2';
import SubTitle from '../../../ui/SubTitle/SubTitle';
import Fade from 'react-reveal/Fade';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import { Link } from "react-router-dom";
import logo from './img/logo.jpg'

const ArtistyList = () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		loading === false &&
			EmployeesAPI.getEmployeeForSite('artists')
				.then(result => {
					// //console.log(result);
					if (result.status === 200) {
						setData(result.data)
						setLoading(true)
					}

					if (result.response?.status === 406) {
						//console.log(result.response.data)
						setData([])
						setLoading(true)
					}

					if (result.response?.status === 500) {
						//console.log(result.response.data)
					}
				})
	}, [loading]);

	const troupe = data?.filter(item => {
		return item?.troupe?.toLowerCase().includes("1") && item?.fio?.toLowerCase() !=='soundrama'
	})

	const invited = data?.filter(item => {
		return item?.troupe?.toLowerCase().includes("0")
	})

	const imgPath = "https://teatrcdr.ru/teatr-api/img/employee/"

	return (
		<div>
			<Fade cascade>
				<Grid container spacing={2}>
					<Grid xs={12} sx={{ pb: '15px' }}>
						<Grid sx={{ mt: '15px' }} container>
							{troupe?.map(user => {
								return (
									<Grid xs={12} sm={6} lg={3} sx={{ pb: '15px' }} key={user?.empID}>
										<Fade>
											<Link className={s.link} to={`./${user?.empID}`} >
												<div className={s.teamItem}>
													<div className={s.artistImg}>
														{user.picture.length !== 0
															? <img src={imgPath + user.empID + '/' + user.picture[0]} alt={user.fio + user.empID} />
															: <img src={logo} alt='ЦДР' />
														}
													</div>
													{user.fio}
												</div>
											</Link>
										</Fade>
									</Grid>
								)
							})}
						</Grid>
					</Grid>
					<Grid xs={12} sx={{ pb: '15px', mt: '35px' }}>
						<MainTitle>Приглашенные артисты:</MainTitle>
						<Grid sx={{ mt: '15px' }} container>
							{invited?.map(user => {
								return (
									<Grid xs={12} sm={6} lg={3} sx={{ pb: '15px' }} key={user?.empID}>
										<Fade>
											<div className={s.teamItem}>
												<div className={s.artistImg}>
													{user.picture.length !== 0
														? <img src={imgPath + user.empID + '/' + user.picture[0]} alt={user.fio + user.empID} />
														: <img src={logo} alt='ЦДР' />
													}

												</div>
												<Link className={s.link} to={`./${user?.empID}`} >{user.fio}</Link>
											</div>
										</Fade>
									</Grid>
								)
							})}
						</Grid>
					</Grid>
				</Grid>
			</Fade>
		</div>
	);
}

export default ArtistyList;
