import React, { useEffect, useState } from 'react';
import s from "./HistoryForm.module.scss";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { nanoid } from 'nanoid'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PerformanceAPI from '../../../../utils/PerformanceAPI';
import PageAPI from '../../../../utils/PageAPI';
import NewsAPI from '../../../../utils/NewsAPI';
import InputFileUI from '../../../../ui/InputFileUI/InputFileUI';
import axios from 'axios'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SubTitle from './../../../../ui/SubTitle/SubTitle';
import TextEditor from '../../../../ui/TextEditor/TextEditor';


const inflection = require('inflection')

const HistoryForm = ({ g1 }) => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState([]);
	const [imgArray, setImgArray] = useState([]);
	const [loadingFiles, setloadingFiles] = useState(false);
	const [prew, setPrew] = useState([]);
	const [show, setShow] = useState([]);
	const [addFile, setaddFile] = useState(false);

	const { id } = useParams()
	const navigate = useNavigate();
	const [redactor, setRedactor] = useState(data.text);


	const { control, handleSubmit, formState: { errors, isDirty, isValid }, setValue, getValues } = useForm(
		{
			mode: "onSubmit"
		}
	);

	//console.log(redactor)
	useEffect(() => {
		PageAPI.getHistory()
			.then(result => {
				// //console.log(result.data);
				if (result.status === 200) {
					setData(result.data[0])
					setRedactor(result.data[0].text)
					setImgArray(result.data[0].picture[0])
					console.log('Массив имен сформирован')
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	useEffect(() => {
		// setValue('newsID', nanoid());
		setValue('picture[]', file);
	}, [setValue, data, loading, file, setImgArray]);

	useEffect(() => {
		// console.log('2. useeffect');
		(loading === true && g1) && urlToObject();

	}, [loading]);

	//Добавить событие
	const add = (form) => {
		form.showID = form.showID.join()
		form.publish = form.publish === true ? 1 : 0;
		form.mainPage = form.mainPage === true ? 1 : 0;

		//console.log(form)

		NewsAPI.addNews(form)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Изменить событие
	const update = (form) => {
		form.text = redactor;

		//console.log(form)
		PageAPI.updateHistory(form)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					// navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Удалить событие
	const deletePerformance = () => {
		// //console.log('Delete')
		NewsAPI.deleteNews(id)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Загрузка фото соответствующего сотрудника
	const urlToObject = () => {
		// console.log('3. urlToObject')

		const dt = new DataTransfer();
		// console.log(imgArray);
		
		const dwnFile = (item) => {
			axios.get(`https://teatrcdr.ru/teatr-api/img/history/${item}`, {
				responseType: 'blob',
				onDownloadProgress: function (progressEvent) {
					let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

				},
			})
				.then(res => {
					dt.items.add(new File([res.data], `https://teatrcdr.ru/teatr-api/img/history/${item}`, { type: res.headers['content-type'] }));
					// console.log(dt.files)
					setFile(dt.files);
				})
		}



		for (var i = 0; i < imgArray.length; i++) {
			dwnFile(imgArray[i]);
		}

		setTimeout(() => {
			setloadingFiles(true);
		}, 500);
		

	}

	const toolbarOptions = {
		toolbar: [
			['bold', 'italic', 'underline', 'strike'],
			['blockquote', 'code-block'],
			[{ 'header': 1 }, { 'header': 2 }],
			[{ 'list': 'ordered' }, { 'list': 'bullet' }],
			['image'],
			['clean']
		]
	};

	//Отрисовка формы
	const renderForm = () => {
		return (
			<>
				<form
					className={s.form}
					onSubmit={
						g1 ? handleSubmit(update) : handleSubmit(add)
					}>

					<SubTitle>Фото</SubTitle>
					{
						loadingFiles === true ? <InputFileUI file={loadingFiles === true && file} setFile={setFile} multiple /> : <CircularProgress />
					}
					

					{/* <SubTitle>Фото 2</SubTitle>
					<InputFileUI file={loadingFiles === true && prew} setFile={setFile} /> */}

					<SubTitle>Описание</SubTitle>
					<div className={s.editor}>
						<TextEditor redactor={redactor} setRedactor={setRedactor} placeholder='Содержание страницы история...' />
					</div>

					{/* <Controller
						name="text"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								label={"Текст"}
								size="small"
								fullWidth
								multiline
								rows={20}
								error={errors?.text?.message && true}
								helperText={errors?.text?.message ? errors?.text?.message : ' '} />}
						rules={{
							required: 'Поле обязательно для заполнения'
						}}
						control={control}
						defaultValue={data?.text ? data?.text : ''}
					/> */}

					<div className={s.button}>
						<Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => navigate(-1)}>
							<>Назад</>
						</Button>

						{/* {g1 !== undefined && <Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => deletePerformance(id)}>Удалить</Button>} */}
						<Button sx={{ borderRadius: '0px' }} variant="outlined" type='submit'>
							{g1 !== undefined ? <>Изменить</> : <>Создать</>}
						</Button>
					</div>

				</form>
			</>
		)
	}

	return (
		<div className={s.container}>
			{loading === true
				?
				renderForm()
				:
				<CircularProgress />
			}
		</div>
	);
}

export default HistoryForm;
