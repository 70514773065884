import axios from 'axios'

class Api {
	constructor() {
		this._baseUrl = 'https://teatrcdr.ru/teatr-api';
	}

	//Добавить спектакль
	addPerformance(data) {
		return axios.post(`${this._baseUrl}/performance`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(res => res)
			.catch(err => err)
	}
	//Удалить спектакль
	deletePerformance(id) {
		return axios.delete(`${this._baseUrl}/performance/${id}`)
			.then(res => res)
			.catch(err => err)
	}

	//Получить спектакли
	getPerformance() {
		return axios.get(`${this._baseUrl}/performance`)
			.then(res => res)
			.catch(err => err)
	}

	//Получить спектакли
	getPerformanceForSite(limit) {
		return axios.get(`${this._baseUrl}/performanceSite`,{
			params: {
				limit: limit
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Получить спектакли по пушктнской карте
	getPerformancePushkin() {
		return axios.get(`${this._baseUrl}/performancePushkin`)
			.then(res => res)
			.catch(err => err)
	}

	//Получить спектакль по ID
	getPerformanceForId(id) {
		return axios.get(`${this._baseUrl}/performance/${id}`)
			.then(res => res)
			.catch(err => err)
	}

	//Получить спектакль по ID для сайта
	getPerformanceForIdSite(id) {
		return axios.get(`${this._baseUrl}/performanceSite/${id}`)
			.then(res => res)
			.catch(err => err)
	}

	//Изменить спектакль
	updatePerformance(id, data) {
		return axios.post(`${this._baseUrl}/performance/${id}`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Получить 4 спектакля кроме id
	getFourPerformance(id) {
		return axios.get(`${this._baseUrl}/getFourPerformance`, {
			params: {
				id: id,
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Получить 1 спектакль кроме id
	getOnePerformance(id) {
		return axios.get(`${this._baseUrl}/getOnePerformance`, {
			params: {
				id: id,
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Получить список спектаклей
	getPerformanceList() {
		return axios.get(`${this._baseUrl}/getPerformanceList`)
			.then(res => res)
			.catch(err => err)
	}

	//Получить список по професси из команды
	// getEmployeeDirector(profession) {
	// 	return axios.get(`${this._baseUrl}/performance`, {
	// 		params: {
	// 			profession: profession
	// 		}
	// 	})
	// 		.then(res => res)
	// 		.catch(err => err)
	// }

}

const PerformanceAPI = new Api();

export default PerformanceAPI;