import axios from 'axios'

class Api {
	constructor() {
		this._baseUrl = 'https://teatrcdr.ru/teatr-api';
	}

	//Добавить лабораторию
	addLab(data) {
		return axios.post(`${this._baseUrl}/labs`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Удалить лабораторию
	deleteLab(id) {
		return axios.delete(`${this._baseUrl}/labs/${id}`)
			.then(res => res)
			.catch(err => err)
	}

	//Получить лаборатории
	getLab() {
		return axios.get(`${this._baseUrl}/labs`)
			.then(res => res)
			.catch(err => err)
	}
	
	//Получить лабораторию по ID
	getLabForId(id) {
		return axios.get(`${this._baseUrl}/labs/${id}`)
			.then(res => res)
			.catch(err => err)
	}

	//Изменить лабораторию
	updateLab(id, data) {
		return axios.post(`${this._baseUrl}/labs/${id}`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(res => res)
			.catch(err => err)
	}

}

const LabsAPI = new Api();

export default LabsAPI;