import React from 'react';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import s from './UserListPage.module.scss'
import { Helmet } from 'react-helmet-async';
import UserList from '../../../components/Admin/Access/UserList/UserList';

const UserListPage = () => {
	return (
		<>
			<Helmet>
				<title>Настройка доступа</title>
				<meta name="description" content="Настройка доступа к панели администратора" />
				<meta name="robots" content="none" />
			</Helmet>

			<MainTitle>Настройка доступа</MainTitle>


			<div className={s.add}>

				<Link to='./add'>
					<Button sx={{ borderRadius: '0px' }} variant="outlined">Добавить</Button>
				</Link>
			</div>

			<div className={s.content}>
				<UserList />
			</div>
		</>
	);
}

export default UserListPage;
