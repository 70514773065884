import React, { useState, useEffect } from 'react';
import s from './NewsList.module.scss'
import EmployeesAPI from '../../../utils/EmployeesAPI';
import NewsAPI from '../../../utils/NewsAPI';
import Grid from '@mui/material/Unstable_Grid2';
import Fade from 'react-reveal/Fade';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import { Link } from "react-router-dom";
import logo from './img/logo.jpg';
import moment from 'moment';

const NewsList = () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		loading === false &&
			NewsAPI.getNews()
				.then(result => {
					// //console.log(result);
					if (result.status === 200) {
						setData(result.data)
						setLoading(true)
					}

					if (result.response?.status === 406) {
						//console.log(result.response.data)
						setData([])
						setLoading(true)
					}

					if (result.response?.status === 500) {
						//console.log(result.response.data)
					}
				})
	}, [loading]);

	const troupe = data?.filter(item => {
		return item?.troupe?.toLowerCase().includes("1")
	})

	const invited = data?.filter(item => {
		return item?.troupe?.toLowerCase().includes("0")
	})

	const imgPath = "https://teatrcdr.ru/teatr-api/img/news/"

	return (
		<div>
			<Fade cascade>
				<Grid container spacing={2}>
					<Grid xs={12} sx={{ pb: '15px' }}>
						<Grid sx={{ mt: '15px' }} container>
							{data?.map(news => {
								return (
									<Grid xs={12} sm={12} lg={6} sx={{ pb: '15px' }} key={news?.newsID}>
										<Fade>
											<Link className={s.link} to={`./${news?.newsID}`} >
												<div className={s.teamItem}>
													<div className={s.artistImg}>
														{news.picture.length !== 0
															? <img src={imgPath + news.newsID + '/' + news.picture[0]} alt={news.title} />
															: <img src={logo} alt='ЦДР' />
														}
													</div>
													<p className={s.newsDate}>{moment(news.newsDate).format('DD.MM.YYYY')}</p>
													<p className={s.newsTitle}>{news.title}</p>
												</div>
											</Link>
										</Fade>
									</Grid>
								)
							})}
						</Grid>
					</Grid>
				</Grid>
			</Fade>
		</div>
	);
}

export default NewsList;
