import React, { useEffect, useState } from 'react';
import s from "./LabForm.module.scss";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { nanoid } from 'nanoid'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputFileUI from '../../../../ui/InputFileUI/InputFileUI';
import SubTitle from '../../../../ui/SubTitle/SubTitle';
import CircularProgress from '@mui/material/CircularProgress';
import PerformanceAPI from '../../../../utils/PerformanceAPI';
import axios from 'axios'
import TextEditor from '../../../../ui/TextEditor/TextEditor';
import LabsAPI from '../../../../utils/LabsAPI';

const inflection = require('inflection')


const LabForm = ({ g1 }) => {

	const [data, setData] = useState([]);
	const [file, setFile] = useState([]);
	const [loadingFiles, setloadingFiles] = useState(false);
	const [imgArray, setImgArray] = useState([]);
	const [prew, setPrew] = useState([]);
	const [loading, setLoading] = useState(false);
	const [redactor, setRedactor] = useState();

	const { id } = useParams()
	const navigate = useNavigate();
	const { control, handleSubmit, formState: { errors }, setValue } = useForm(
		{
			mode: "onSubmit"
		}
	);

	useEffect(() => {
		(loading === true && g1) && (urlToObject())
		// setloadingFiles(true)
	}, [loading]);

	useEffect(() => {
		g1 &&
			LabsAPI.getLabForId(id)
				.then(result => {
					// //console.log(result.data[0]);
					if (result.status === 200) {
						setData(result.data[0])
						setImgArray(result.data[0]?.picture)
						setRedactor(result.data[0].text)
						setLoading(true)
					}

					if (result.response?.status === 500) {
						// //console.log(result.response.data)
					}
				})

		g1 === undefined && setLoading(true)

	}, [g1]);

	useEffect(() => {
		setValue('labID', nanoid());
		setValue('picture[]', file);
	}, [setValue, file, data, loading]);

	//Загрузка фото соответствующего сотрудника
	const urlToObject = async () => {
		const dt = new DataTransfer();

		const dwnFile = (item) => {
			axios.get(`https://teatrcdr.ru/teatr-api/img/labs/${data?.labID}/${item}`, {
				responseType: 'blob',
				onDownloadProgress: function (progressEvent) {
					let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
					// console.log(percentCompleted)
				},
			})
				.then(res => {
					dt.items.add(new File([res.data], `https://teatrcdr.ru/teatr-api/img/labs/${data?.labID}/${item}`, { type: res.headers['content-type'] }));
					// console.log(dt.files)
					setFile(dt.files);
					// setPrew(dt.files);
				})
				.catch(err => err)
		}

		for (var i = 0; i < imgArray.length; i++) {
			dwnFile(imgArray[i]);
		}

		setTimeout(() => {
			setloadingFiles(true);
		}, 500);

	}

	//Добавить спектакль
	const add = (form) => {
		form.text = redactor;

		//console.log(form)
		LabsAPI.addLab(form)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Изменить спектакль
	const update = (form) => {
		form.text = redactor;

		//console.log(form)
		LabsAPI.updateLab(id, form)
			.then(result => {

				if (result.status === 200) {
					// //console.log(result);
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Удалить сотрудника
	const deletePerformance = () => {
		// //console.log('Delete')
		LabsAPI.deleteLab(id)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Отрисовка формы
	const renderForm = () => {
		return (
			<>
				<form
					className={s.form}
					onSubmit={
						g1 ? handleSubmit(update) : handleSubmit(add)
					}>

					<SubTitle>Фото:</SubTitle>

					{/* <InputFileUI file={file} setFile={setFile} multiple /> */}

					{
						loadingFiles === true ? <InputFileUI file={loadingFiles === true && file} setFile={setFile} multiple /> : <CircularProgress />
					}
					

					<Controller
						name="name"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								label="Название*"
								size="small"
								fullWidth
								multiline
								rows={3}
								error={errors?.name?.message && true}
								helperText={errors?.name?.message ? errors?.name?.message : ' '} />}
						rules={{
							required: 'Поле обязательно для заполнения',
							maxLength: { value: 500, message: 'Максимум 500 символов' },
							pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Название не может содержать спецсимволов' }
						}}

						control={control}
						defaultValue={data?.name ? data?.name : ''}
					/>

					<Controller
						name="number"
						render={({ field }) => <TextField {...field} variant="outlined" label="Номер в списке" size="small" error={errors?.number?.message && true} helperText={errors?.number?.message ? errors?.number?.message : ' '} />}
						rules={{
							// required: 'Поле обязательно для заполнения',
							pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Имя пользователя не может содержать спецсимволов' }
						}}
						control={control}
						defaultValue={data?.number ? data?.number : 500}
					/>

					<Controller
						name="description"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								label={"Краткое описание*"}
								size="small"
								multiline
								rows={5}
								fullWidth
								error={errors?.description?.message && true}
								helperText={errors?.description?.message ? errors?.description?.message : ' '} />}
						rules={{
							required: 'Поле обязательно для заполнения',
							maxLength: { value: 1000, message: 'Максимум 1000 символов' },
							pattern: { value: /[^@#$%^&*()_]+$/, message: 'Имя пользователя не может содержать спецсимволов' }
						}}
						control={control}
						defaultValue={data?.description ? data?.description : ''}
					/>
					<div className={s.textEditor}>
						<TextEditor redactor={redactor} setRedactor={setRedactor} placeholder='Содержание страницы лаборатории...' />
					</div>

					<div className={s.button}>
						<Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => navigate(-1)}>
							<>Назад</>
						</Button>

						{g1 !== undefined && <Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => deletePerformance(id)}>Удалить</Button>}
						<Button sx={{ borderRadius: '0px' }} variant="outlined" type='submit'>
							{g1 !== undefined ? <>Изменить</> : <>Создать</>}
						</Button>
					</div>

				</form>
			</>
		)
	}

	return (
		<div className={s.container}>
			{loading === true
				?
				renderForm()
				:
				<CircularProgress />
			}
		</div>
	);
}

export default LabForm;
