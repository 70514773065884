import React from 'react';
import s from './MainTitle.module.scss'
import { useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const MainTitle = ({ children, back }) => {
	const navigate = useNavigate();

	const cancel = () => {
		navigate(-1)
	}

	return (
		<div className={s.title}>
			{back === true &&
				<IconButton sx={{ mb: '10px', borderRadius: '0px', border: '1px solid' }} aria-label="delete" color='primary' onClick={() => cancel()}>
					<ArrowBackOutlinedIcon />
				</IconButton>}

			<h1 className={s.mainTitle}>
				{children}
			</h1>
		</div>

	);
}

export default MainTitle;
