import React from 'react';
import s from './ImpairedVersion.module.scss'
import Button from '@mui/material/Button';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';




const ImpairedVersion = () => {

	const initImpairedVersion = () => {
		//console.log('Версия для слабовидящих')
	}




	return (
		<div className={s.impaired}>
			{/* <Button variant="outlined" sx={{ fontSize: '8px' }} startIcon={<RemoveRedEyeOutlinedIcon />} onClick={() => initImpairedVersion()}> */}
				<a href="#" id={s.id} className="js-version">Версия для слабовидящих</a>
			{/* </Button> */}
		</div>
	);
}

export default ImpairedVersion;
