import React from 'react';
import s from './AreaListItem.module.scss'
const inflection = require('inflection')

const AreaListItem = ({ data }) => {


	return (
		<div className={s.line} >
			<div className={s.fio}>{inflection.titleize(data?.name)}</div>
			<div className={s.login}>
				Площадка
			</div>
		</div>
	);
}

export default AreaListItem;
