import React, { useEffect, useState } from 'react';
import s from "./ProjectForm.module.scss";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { nanoid } from 'nanoid'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputFileUI from '../../../../ui/InputFileUI/InputFileUI';
import SubTitle from '../../../../ui/SubTitle/SubTitle';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import TextEditor from '../../../../ui/TextEditor/TextEditor';
import ProjectAPI from '../../../../utils/ProjectAPI';

const inflection = require('inflection')


const ProjectForm = ({ g1 }) => {

	const [data, setData] = useState([]);
	const [file, setFile] = useState([]);
	const [loadingFiles, setloadingFiles] = useState(false);
	const [imgArray, setImgArray] = useState([]);
	const [prew, setPrew] = useState([]);
	const [loading, setLoading] = useState(false);
	const [redactor, setRedactor] = useState();

	const { id } = useParams()
	const navigate = useNavigate();
	const { control, handleSubmit, formState: { errors }, setValue } = useForm(
		{
			mode: "onSubmit"
		}
	);

	useEffect(() => {
		(loading === true && g1) && (urlToObject())
		setloadingFiles(true)
	}, [loading]);

	useEffect(() => {
		g1 &&
			ProjectAPI.getProjectForId(id)
				.then(result => {
					// //console.log(result.data[0]);
					if (result.status === 200) {
						setData(result.data[0])
						setImgArray(result.data[0]?.picture)
						setRedactor(result.data[0].text)
						setLoading(true)
					}

					if (result.response?.status === 500) {
						// //console.log(result.response.data)
					}
				})

		g1 === undefined && setLoading(true)

	}, [g1]);

	useEffect(() => {
		setValue('projectID', nanoid());
		setValue('picture[]', file);
	}, [setValue, setFile, file, data, loading]);

	//Загрузка фото соответствующего сотрудника
	const urlToObject = async () => {
		const dt = new DataTransfer();

		const dwnFile = (item) => {
			axios.get(`https://teatrcdr.ru/teatr-api/img/project/${data?.projectID}/${item}`, {
				responseType: 'blob',
				onDownloadProgress: function (progressEvent) {
					let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
				},
			})
				.then(res => {
					dt.items.add(new File([res.data], `https://teatrcdr.ru/teatr-api/img/project/${data?.projectID}/${item}`, { type: res.headers['content-type'] }));
					setFile(dt.files);
					// setPrew(dt.files);
				})
				.catch(err => err)
		}

		for (var i = 0; i < imgArray.length; i++) {
			dwnFile(imgArray[i]);
		}

		// const downloadImg = await Promise.all(
		// 	imgArray.map(async item => {
		// 		await axios.get(`https://teatrcdr.ru/teatr-api/img/project/${data?.projectID}/${item}`, {
		// 			responseType: 'blob'
		// 		})
		// 			.then(res => {
		// 				// //console.log(res)
		// 				res.headers['content-type'].includes('image') &&
		// 					dt.items.add(new File([res.data], `https://teatrcdr.ru/teatr-api/img/project/${data?.projectID}/${item}`, { type: res.headers['content-type'] }));

		// 			})
		// 			.catch(err => err)
		// 	})
		// )
		// // //console.log(dt.items)
		// // //console.log(dt.files)
		// var file_list = dt.files;
		// // //console.log(file_list[0] instanceof File);
		// setFile(file_list);
		// setPrew(file_list);
	}

	//Добавить спектакль
	const add = (form) => {
		form.text = redactor;

		//console.log(form)
		ProjectAPI.addProject(form)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Изменить спектакль
	const update = (form) => {
		form.text = redactor;

		//console.log(form)
		ProjectAPI.updateProject(id, form)
			.then(result => {

				if (result.status === 200) {
					// //console.log(result);
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Удалить сотрудника
	const deletePerformance = () => {
		// //console.log('Delete')
		ProjectAPI.deleteProject(id)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Отрисовка формы
	const renderForm = () => {
		return (
			<>
				<form
					className={s.form}
					onSubmit={
						g1 ? handleSubmit(update) : handleSubmit(add)
					}>

					{/* <SubTitle>Фото:</SubTitle>

					<InputFileUI file={file} setFile={setFile} multiple /> */}

					<Controller
						name="name"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								label="Название*"
								size="small"
								fullWidth
								multiline
								rows={3}
								error={errors?.name?.message && true}
								helperText={errors?.name?.message ? errors?.name?.message : ' '} />}
						rules={{
							required: 'Поле обязательно для заполнения',
							maxLength: { value: 500, message: 'Максимум 500 символов' },
							pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Название не может содержать спецсимволов' }
						}}

						control={control}
						defaultValue={data?.name ? data?.name : ''}
					/>

					<Controller
						name="number"
						render={({ field }) => <TextField {...field} variant="outlined" label="Номер в списке" size="small" error={errors?.number?.message && true} helperText={errors?.number?.message ? errors?.number?.message : ' '} />}
						rules={{
							// required: 'Поле обязательно для заполнения',
							pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Имя пользователя не может содержать спецсимволов' }
						}}
						control={control}
						defaultValue={data?.number ? data?.number : 500}
					/>

					<Controller
						name="description"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								label={"Краткое описание*"}
								size="small"
								multiline
								rows={5}
								fullWidth
								error={errors?.description?.message && true}
								helperText={errors?.description?.message ? errors?.description?.message : ' '} />}
						rules={{
							required: 'Поле обязательно для заполнения',
							maxLength: { value: 1000, message: 'Максимум 1000 символов' },
							pattern: { value: /[^@#$%^&*()_]+$/, message: 'Имя пользователя не может содержать спецсимволов' }
						}}
						control={control}
						defaultValue={data?.description ? data?.description : ''}
					/>

					<SubTitle>Описание</SubTitle>
					<div className={s.textEditor}>
						<TextEditor redactor={redactor} setRedactor={setRedactor} placeholder='Содержание страницы лаборатории...' />
					</div>

					<div className={s.button}>
						<Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => navigate(-1)}>
							<>Назад</>
						</Button>

						{g1 !== undefined && <Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => deletePerformance(id)}>Удалить</Button>}
						<Button sx={{ borderRadius: '0px' }} variant="outlined" type='submit'>
							{g1 !== undefined ? <>Изменить</> : <>Создать</>}
						</Button>
					</div>

				</form>
			</>
		)
	}

	return (
		<div className={s.container}>
			{loading === true
				?
				renderForm()
				:
				<CircularProgress />
			}
		</div>
	);
}

export default ProjectForm;
