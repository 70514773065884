import React, { useState, useEffect } from 'react';
import s from './SearchPageSite.module.scss'
import MainTitle from '../../ui/MainTitle/MainTitle';
import HelmetUI from '../../ui/HelmetUI/HelmetUI';
import { Routes, Route, useParams, Link } from "react-router-dom";
import SearchAPI from '../../utils/SearchAPI';
import { useForm, Controller } from "react-hook-form";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import SearchMainPage from '../../components/SearchMainPage/SearchMainPage';

const SearchPageSite = () => {

	const [data, setData] = useState([]);
	const [request, setRequest] = useState(new URLSearchParams(window.location.search).get('request'));
	const [loading, setLoading] = useState(false);
	const { control, handleSubmit, setValue } = useForm({ mode: "onSubmit" });

	useEffect(() => {

	}, []);
	// console.log(window.location.href);

	const setSearchRequest = (data) => {
		window.location.search = '?request='+ data.search;

		// console.log(data.search)
		// SearchAPI.get(data.search)
		// 	.then(result => {
		// 		// //console.log(result.data);
		// 		if (result.status === 200) {
		// 			setData(result.data)
		// 			// setImgArray(result.data[0].picture)
		// 			setLoading(true)
		// 		}

		// 		if (result.response?.status === 500) {
		// 			// //console.log(result.response.data)
		// 		}
		// 	})
	}

	useEffect(() => {
		setValue('search', request);

		SearchAPI.get(request)
			.then(result => {
				// //console.log(result.data);
				if (result.status === 200) {
					setData(result.data)
					// setImgArray(result.data[0].picture)
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);








	// //console.log(data)
	return (
		<div>
			<div className={s.container}>
				<Routes>
					<Route index element={<HelmetUI title="Поиск - Центр драматургии и режиссуры" description="Театр Центр драматургии и режиссуры" />} />
				</Routes>

				<div className={s.content}>
					{loading === true &&
						<div className={s.flex_container}>

							<MainTitle>Поиск</MainTitle>
							<form className={s.form} onSubmit={handleSubmit((data) => setSearchRequest(data))}>
								<Controller
									render={({ field }) => <TextField sx={{ input: { pt: '28px', pb: '5px'} }} {...field} variant="standard" fullWidth />}
									name="search"
									control={control}
								// defaultValue={request && request}
								/>
								<IconButton type="submit" sx={{ p: '2px', borderRadius: '0px', border: '1px solid #ddd', marginTop: '16px' }} aria-label="search">
									<SearchIcon sx={{ fontSize: '35px' }} />
								</IconButton>
							</form>
							{/* <div className={s.text} dangerouslySetInnerHTML={{ __html: data.text }}></div> */}

							<div className={s.resultList}>
								{data.length === 0 && <p>По вашему запросу ничего не найдено</p>}
								{data.map(item => {
									return <div>
										<Link to={item.url} className={s.itemList}>{item.text}</Link>
										<p className={s.type}>{item.type}</p>
									</div>
								})}
							</div>

						</div>}

				</div>
			</div>
		</div >
	);
}

export default SearchPageSite;
