import React from 'react';
import s from './TeamPageSite.module.scss'
import MainTitle from '../../ui/MainTitle/MainTitle';
import HelmetUI from '../../ui/HelmetUI/HelmetUI';
import { Routes, Route } from "react-router-dom";
import TeamListSite from '../../components/TeamSite/TeamListSite/TeamListSite';
import OneTeamInformation from '../../components/TeamSite/OneTeamInformation/OneTeamInformation';

const TeamPageSIte = () => {
	return (
		<div className={s.container}>
			<Routes>
				<Route index element={<HelmetUI title="Команда - Центр драматургии и режиссуры" description="Театр Центр драматургии и режиссуры" />} />
			</Routes>

			<Routes>
				<Route index element={<MainTitle>Команда</MainTitle>} />
			</Routes>

			<div className={s.content}>
				<Routes>
					<Route index element={<TeamListSite />} />
					<Route path=":id" element={<OneTeamInformation />} />
				</Routes>
			</div>
		</div>
	);
}

export default TeamPageSIte;
