import React, { useEffect, useState } from 'react';
import s from "./NewsForm.module.scss";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { nanoid } from 'nanoid'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import PerformanceAPI from '../../../../utils/PerformanceAPI';
import EventAPI from '../../../../utils/EventAPI';
import NewsAPI from '../../../../utils/NewsAPI';
import moment from 'moment';
import InputFileUI from '../../../../ui/InputFileUI/InputFileUI';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import axios from 'axios'
import SubTitle from '../../../../ui/SubTitle/SubTitle';


const inflection = require('inflection')

const NewsForm = ({ g1 }) => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState([]);
	const [imgArray, setImgArray] = useState([]);
	const [loadingFiles, setloadingFiles] = useState(false);
	const [prew, setPrew] = useState([]);
	const [show, setShow] = useState([]);
	const { id } = useParams()
	const navigate = useNavigate();
	const { control, handleSubmit, formState: { errors, isDirty, isValid }, setValue, getValues } = useForm(
		{
			mode: "onSubmit"
		}
	);

	useEffect(() => {
		PerformanceAPI.getPerformance()
			.then(result => {
				// //console.log(result.data);
				if (result.status === 200) {
					setShow(result.data)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	useEffect(() => {
		g1 &&
			NewsAPI.getNewsForId(id)
				.then(result => {
					// //console.log(result.data[0]);
					if (result.status === 200) {
						setData(result.data[0])
						setImgArray(result.data[0]?.picture)
						setLoading(true)
					}

					if (result.response?.status === 500) {
						// //console.log(result.response.data)
					}
				})

		g1 === undefined && setLoading(true)

	}, [g1]);

	useEffect(() => {
		setValue('newsID', nanoid());
		setValue('picture[]', file);
	}, [setValue, data, loading, file]);

	useEffect(() => {
		(loading === true && g1) && (urlToObject())
		setloadingFiles(true)
	}, [loading]);

	// console.log(moment().format('YYYY-MM-DD HH:mm:ss'))

	//Добавить событие
	const add = (form) => {
		form.showID = form.showID.join()
		form.publish = form.publish === true ? 1 : 0;
		form.mainPage = form.mainPage === true ? 1 : 0;
		form.archive = form.archive === true ? 1 : 0;
		form.archiveDate = form.archive === 1 ? moment().format('YYYY-MM-DD HH:mm:ss') : '';
		//console.log(form)

		NewsAPI.addNews(form)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Изменить событие
	const update = (form) => {
		form.showID = form.showID.join()
		form.publish = form.publish === true ? 1 : 0;
		form.mainPage = form.mainPage === true ? 1 : 0;
		form.archive = form.archive === true ? 1 : 0;
		form.archiveDate = form.archive === 1 ? moment().format('YYYY-MM-DD HH:mm:ss') : '0000-00-00 00:00:00';

		// console.log(form)
		NewsAPI.updateNews(id, form)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Удалить событие
	const deletePerformance = () => {
		// //console.log('Delete')
		NewsAPI.deleteNews(id)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Отрисовка формы
	const renderForm = () => {
		return (
			<>
				<form
					className={s.form}
					onSubmit={
						g1 ? handleSubmit(update) : handleSubmit(add)
					}>

					<Controller
						name="title"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								label={"Заголовок"}
								size="small"
								fullWidth
								error={errors?.title?.message && true}
								helperText={errors?.title?.message ? errors?.title?.message : ' '} />}
						rules={{
							required: 'Поле обязательно для заполнения'
						}}
						control={control}
						defaultValue={data?.title ? data?.title : ''}
					/>

					<div className={s.checkBoxFlex}>
						<Controller
							name="publish"
							render={({ field }) => <FormControlLabel sx={{ mb: '20px' }} required control={<Switch
								onChange={(e) => field.onChange(e.target.checked)}
								checked={field.value}

							/>} label="Опубликовать" />}
							control={control}
							defaultValue={data?.publish === '1' ? true : false}
						/>

						<Controller
							name="mainPage"
							render={({ field }) => <FormControlLabel sx={{ mb: '20px' }} required control={<Switch
								onChange={(e) => field.onChange(e.target.checked)}
								checked={field.value}

							/>} label="На главную" />}
							control={control}
							defaultValue={data?.mainPage === '1' ? true : false}
						/>

						<Controller
							name="archive"
							render={({ field }) => <FormControlLabel sx={{ mb: '20px' }} required control={<Switch
								onChange={(e) => field.onChange(e.target.checked)}
								checked={field.value}

							/>} label="Архивировать" />}
							control={control}
							defaultValue={data?.archive === '1' ? true : false}
						/>
					</div>

					<SubTitle>Фото:</SubTitle>
					{/* <InputFileUI file={file} setFile={setFile} /> */}

					{
						loadingFiles === true ? <InputFileUI file={loadingFiles === true && file} setFile={setFile}/> : <CircularProgress />
					}
					<br></br>

					<Controller
						name="text"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								label={"Текст"}
								size="small"
								fullWidth
								multiline
								rows={20}
								error={errors?.text?.message && true}
								helperText={errors?.text?.message ? errors?.text?.message : ' '} />}
						rules={{
							required: 'Поле обязательно для заполнения'
						}}
						control={control}
						defaultValue={data?.text ? data?.text : ''}
					/>

					<Controller
						name="showID"
						control={control}
						defaultValue={data?.showID ? data?.showID?.split(',') : []}
						render={({ field }) =>
							<FormControl fullWidth error={errors?.showID?.message ? true : false}>
								<InputLabel>Спектакль</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									{...field}
									label={"Спектакль"}
									multiple
								>
									{show?.map(((item) => {
										return <MenuItem key={item?.showID} value={item?.showID}>{item?.name.toUpperCase()}</MenuItem>
									}))}

								</Select>
								<FormHelperText>{errors?.showID?.message ? errors?.showID?.message : ' '}</FormHelperText>
							</FormControl>
						}
					/>

					<Controller
						name="newsDate"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								size="small"
								type='datetime-local'
								InputLabelProps={{ shrink: true }}
								label={"Дата:"}
								error={errors?.newsDate?.message && true}
								helperText={errors?.newsDate?.message ? errors?.newsDate?.message : ' '} />}
						rules={{
							required: 'Поле обязательно для заполнения',
						}}
						control={control}
						defaultValue={data?.newsDate ? data?.newsDate : moment().startOf('day').add(12, 'h').format('YYYY-MM-DD HH:mm:ss')}
					/>

					<div className={s.button}>
						<Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => navigate(-1)}>
							<>Назад</>
						</Button>

						{g1 !== undefined && <Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => deletePerformance(id)}>Удалить</Button>}
						<Button sx={{ borderRadius: '0px' }} variant="outlined" type='submit'>
							{g1 !== undefined ? <>Изменить</> : <>Создать</>}
						</Button>
					</div>

				</form>
			</>
		)
	}

	//Загрузка фото соответствующего сотрудника
	const urlToObject = () => {
		const dt = new DataTransfer();

		const dwnFile = (item) => {
			axios.get(`https://teatrcdr.ru/teatr-api/img/news/${data?.newsID}/${item}`, {
				responseType: 'blob',
				onDownloadProgress: function (progressEvent) {
					let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
					console.log(percentCompleted)
				},
			})
				.then(res => {
					dt.items.add(new File([res.data], `https://teatrcdr.ru/teatr-api/img/news/${data?.newsID}/${item}`, { type: res.headers['content-type'] }));
					console.log(dt.files)
					setFile(dt.files);
					// setPrew(dt.files);
				})
				.catch(err => err)
		}

		for (var i = 0; i < imgArray.length; i++) {
			dwnFile(imgArray[i]);
		}

		setTimeout(() => {
			setloadingFiles(true);
		}, 500);

	}

	return (
		<div className={s.container}>
			{loading === true
				?
				renderForm()
				:
				<CircularProgress />
			}
		</div>
	);
}

export default NewsForm;
