import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from "react-router-dom";
import s from './HomePage.module.scss'
// import MainTitle from '../../ui/MainTitle/MainTitle';
import HelmetUI from '../../ui/HelmetUI/HelmetUI';
// import { Routes, Route } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Scrollbar, Navigation } from "swiper";
import EventAPI from '../../utils/EventAPI';
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
// import ShowPageSite from '../ShowPageSite/ShowPageSite';
import ShowListSite from '../../components/ShowSite/ShowListSite/ShowListSite';
import ShowSliderListSite from '../../components/ShowSite/ShowSliderListSite/ShowSliderListSite';
// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
import { format } from 'date-fns';
import moment from 'moment';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import logo from './img/logo.jpg'
import homeAboutPic from './img/home-about-pic.jpg'
import NewsListHome from "../../components/NewsSite/NewsListHome/NewsListHome";
import HomeAreas from "../../components/HomeAreas/HomeAreas";





const performanceImgPath = "https://teatrcdr.ru/teatr-api/img/performance/";
const newsImgPath = "https://teatrcdr.ru/teatr-api/img/news/";

const HomePage = () => {

	const [events, setEvents] = useState([]);
	const [news, setNews] = useState([]);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [url, setUrl] = useState('');
	const [activeIndex, setActiveIndex] = useState(0);

	const handleOpen = (url) => {
		setUrl(url);
		// setOpen(true);
		window.TLIframe.showPopup({ url: url })
	}

	const handleClose = () => {
		setUrl('');
		setOpen(false);
	}

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		height: '80%',
		bgcolor: 'background.paper',
		padding: '20px 5px'
	};



	useEffect(() => {
		EventAPI.getEventForMainPage(5)
			.then(result => {

				if (result.status === 200) {
					setEvents(result.data)
					setLoading(true)
				}

				if (result.response?.status === 406) {
					// //console.log(result.response.data)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	const filter = events?.filter((user, index) => {
		return (
			index === activeIndex
		)
	})



	return (

		<div className={s.mainPage}>
			<HelmetUI title="Центр драматургии и режиссуры (ЦДР) " description="Театр Центр драматургии и режиссуры" />

			{loading === true &&
				<>
					{events.length !== 0 &&
						<div className={s.carousel}>
							<Swiper
								spaceBetween={30}
								effect={"fade"}
								crossFade={true}
								autoplay={{
									delay: 10000,
									disableOnInteraction: true,
									pauseOnMouseEnter: true
								}}
								loop={true}
								modules={[EffectFade, Autoplay, Scrollbar, Navigation]}
								navigation
								style={{
									'--swiper-navigation-color': '#fff',
									'--swiper-pagination-color': '#fff',
								}}
								className={s.imageContainer}
								onRealIndexChange={(element) => setActiveIndex(element.realIndex)}
							>
								{events?.map(event => {
									return <SwiperSlide key={event?.eventID}>
										<div className={s.carouselItem}>
											{event?.eventDate
												? <img className={s.carouselImg}
															 src={event?.picture[0] ? performanceImgPath + event?.performance + '/' + event?.picture[0] : logo}
															 alt={event?.performanceName}/>
												:
												<img className={s.carouselImg} src={newsImgPath + event?.performance + '/' + event?.picture[0]}
														 alt={event?.performanceName}/>
											}

										</div>
									</SwiperSlide>
								})}
							</Swiper>
						</div>
					}

					{filter?.map(event => {
						return (
							<div className={s.showInformation} key={event?.eventID}>
								<div className={s.showTitle}>
									{event?.eventDate
										? <Link to={`/spektakli/${event?.performance}`}>{event.performanceName}</Link>
										: <Link to={`/sobytiya/${event?.performance}`}>{event.performanceName}</Link>
									}
								</div>

								<div className={s.showDescription}>
									<div
										dangerouslySetInnerHTML={{__html: event?.description?.length > 300 ? event?.description?.slice(0, 300) + '...' : event?.description}}></div>
								</div>

								{event?.eventDate &&
									<div className={s.saleButtonBlock} onClick={() => handleOpen(event.ticketland)}>
										<div className={s.eventStyle}>
											<p>{moment(event.eventDate).format('DD.MM.yyyy HH:mm')}</p>
											<p>Площадка: {event?.area?.charAt(0).toUpperCase() + event?.area?.slice(1)}</p>
											<button className={s.saleButton}>КУПИТЬ БИЛЕТ</button>
										</div>
									</div>
								}
							</div>
						)
					})}

					<div className={s.sectionHeader}>
						<h2>Ближайшие спектакли</h2>
						<div className={s.allLink}><a href="/afisha">Вся афиша</a></div>
					</div>

					<ShowSliderListSite/>

					<div className={s.sectionHeader}>
						<h2>Замены и переносы спектаклей</h2>
						<div className={s.allLink}><a href="/sobytiya">Все события</a></div>
					</div>

					<div className="homeNewsWrapper">
						<NewsListHome/>
					</div>

					<div className={s.sectionHeader}>
						<h2>О театре</h2>
					</div>

					<div className={s.homeAbout}>
						<div className={s.homeAboutCol}>
							<div className={s.homeAboutPic}>
								<img src={homeAboutPic} alt="О театре"/>
							</div>
						</div>
						<div className={s.homeAboutCol}>
							<div className={s.homeAboutDescr}>
								<p>Театр «Центр драматургии и режиссуры» (ЦДР) был основан в 1998 году Алексеем Казанцевым и Михаилом
									Рощиным и сразу вошёл в театральную жизнь Москвы как «открытое пространство» для творческой
									молодёжи.
									ЦДР стал первой профессиональной площадкой для нескольких поколений молодых режиссёров, драматургов,
									артистов, художников.</p>

								<p>Сегодня ЦДР – это открытая площадка для выпускников художественных, театральных вузов, пространство
									для эксперимента и «перехода» из ученичества в профессиональную среду. ЦДР стоит на пути возрождения
									театра в том виде, в каком его когда-то задумали Алексей Казанцев и Михаил Рощин.</p>
							</div>
						</div>
					</div>

					<div className={s.sectionHeader}>
						<h2>Площадки ЦДР</h2>
						<div className={s.allLink}><a href="/o-teatre/ploshhadki">Все площадки</a></div>
					</div>

					<HomeAreas />


					{/*<ShowListSite />*/}
				</>
			}
		</div>

	);
}

export default HomePage;
