import axios from 'axios'

class Api {
	constructor() {
		this._baseUrl = 'https://teatrcdr.ru/teatr-api';
	}

	//Добавить проект
	addProject(data) {
		return axios.post(`${this._baseUrl}/project`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Удалить проект
	deleteProject(id) {
		return axios.delete(`${this._baseUrl}/project/${id}`)
			.then(res => res)
			.catch(err => err)
	}

	//Получить проекты
	getProject() {
		return axios.get(`${this._baseUrl}/project`)
			.then(res => res)
			.catch(err => err)
	}
	
	//Получить проект по ID
	getProjectForId(id) {
		return axios.get(`${this._baseUrl}/project/${id}`)
			.then(res => res)
			.catch(err => err)
	}

	//Изменить проект
	updateProject(id, data) {
		return axios.post(`${this._baseUrl}/project/${id}`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(res => res)
			.catch(err => err)
	}
}

const ProjectAPI = new Api();

export default ProjectAPI;