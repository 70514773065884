import React from 'react';
import s from './NewsPageSite.module.scss'
import MainTitle from '../../ui/MainTitle/MainTitle';
import HelmetUI from '../../ui/HelmetUI/HelmetUI';
import { Routes, Route } from "react-router-dom";
import NewsList from '../../components/NewsSite/NewsList/NewsList';
import NewsInformation from '../../components/NewsSite/NewsInformation/NewsInformation';

const NewsPageSite = () => {
	return (
		<div>
			<div className={s.container}>
				<Routes>
					<Route index element={<HelmetUI title="События - Центр драматургии и режиссуры" description="Театр Центр драматургии и режиссуры" />} />
				</Routes>

				<Routes>
					<Route index element={<MainTitle>События</MainTitle>} />
				</Routes>

				<div className={s.content}>
					<Routes>
						<Route index element={<NewsList />} />
						<Route path=":id" element={<NewsInformation />} />
					</Routes>
				</div>
			</div>
		</div>
	);
}

export default NewsPageSite;
