import React from 'react';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import { useNavigate } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import IconButton from '@mui/material/IconButton';
import s from './UserAddPage.module.scss'
import { Helmet } from 'react-helmet-async';
import UserForm from '../../../components/Admin/Access/UserForm/UserForm';



const UserAddPage = () => {
	const navigate = useNavigate();

	const cancel = () => {
		navigate(-1)
	}

	return (
		<>
			<Helmet>
				<title>Добавить нового пользователя</title>
				<meta name="description" content="Добавить нового пользователя к панели администратора" />
				<meta name="robots" content="none" />
			</Helmet>

			<div className={s.title}>
				<IconButton sx={{mb: '10px', borderRadius: '0px', border: '1px solid'}} aria-label="delete" color='primary' onClick={() => cancel()}>
					<ArrowBackOutlinedIcon />
				</IconButton>
				<MainTitle>Добавить нового пользователя</MainTitle>
			</div>

			<div className={s.content}>
				<UserForm />
			</div>
		</>
	);
}

export default UserAddPage;
