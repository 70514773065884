import React, { useEffect, useState } from 'react';
import s from './ProjectList.module.scss';
import { Link } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import Search from '../../../Search/Search';
import Pagination from '@mui/material/Pagination';
import ProjectAPI from '../../../../utils/ProjectAPI';
import ProjectListItem from '../ProjectListItem/ProjectListItem';

const ProjectList = () => {

	const [employees, setEmployees] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchRequest, setSearchRequest] = useState("");
	const [type, setType] = useState('artists');
	const [page, setPage] = useState(1);
	const [maxPage, setMaxPage] = useState(0);
	const [maxListItem] = useState(10);
	const [beginIndex, setBeginIndex] = useState(0)
	const [endIndex, setEndIndex] = useState(10)

	useEffect(() => {
		if (searchRequest !== "") {
			setMaxPage(0);
		} else {
			setMaxPage(Math.ceil(employees.length / maxListItem))
		}
	}, [searchRequest, maxListItem, employees])

	useEffect(() => {
		loading === false &&
			ProjectAPI.getProject()
				.then(result => {
					//console.log(result);
					if (result.status === 200) {
						setEmployees(result.data)
						setMaxPage(Math.ceil(result.data.length / maxListItem))
						setLoading(true)
					}

					if (result.response?.status === 406) {
						//console.log(result.response.data)
						setEmployees([])
						setLoading(true)
					}

					if (result.response?.status === 500) {
						//console.log(result.response.data)
					}
				})
	}, [loading, maxListItem, type]);

	const filter = employees?.filter((user) => {
		return (
			user?.name?.toLowerCase().includes(searchRequest.toLowerCase()) 
		)
	})

	const showUsersByPage = filter?.map((item, index) => {
		if (index >= beginIndex && index < endIndex) {
			return (
				<Link to={`./${item?.projectID}`
				} key={item?.projectID}>
					<ProjectListItem data={item} />
				</Link>
			)
		}
	})

	const showUsersWhithoutLimits = filter?.map((item) => {
		return (
			<Link to={`./${item?.projectID}`
			} key={item?.projectID}>
				<ProjectListItem data={item} />
			</Link>
		)
	})

	const changePage = (event, value) => {
		setBeginIndex(value * maxListItem - maxListItem);
		setEndIndex(value * maxListItem)
		setPage(value);
	}

	const renderList = () => {
		return (
			<>
				<div className={s.search}>
					<Search setSearchRequest={setSearchRequest} />
				</div>

				<div className={s.container}>
					{maxPage !== 0
						? showUsersByPage
						: showUsersWhithoutLimits}
					{showUsersByPage.length === 0 && <>Нет записей...</>}
				</div>

				<div className={s.pagination}>
					{((maxPage !== 1 && employees.length !== 0) && (maxPage !== 0 && employees.length !== 0)) &&
						<Pagination sx={{ button: { borderRadius: '0px', color: 'text.primary' }, ul: {display: 'flex', gap: '1px'} }} count={maxPage} page={page} variant="outlined" size='large' shape="rounded" boundaryCount={0} siblingCount={1} hidePrevButton hideNextButton onChange={changePage} />
					}
				</div>
			</>

		)
	}

	return (
		<div>
			{loading === true
				?
				renderList()
				:
				<CircularProgress />
			}
		</div>
	);
}

export default ProjectList;
