import React from 'react';
import s from './PushkinPageSite.module.scss'
import MainTitle from '../../ui/MainTitle/MainTitle';
import HelmetUI from '../../ui/HelmetUI/HelmetUI';
import { Link, Routes, Route } from "react-router-dom";

import ShowListPushkinSite from '../../components/ShowSite/ShowListPushkinSite/ShowListPushkinSite';
import ShowInformation from '../../components/ShowSite/ShowInformation/ShowInformation';

const PushkinPageSite = () => {

	return (
		<div className={s.container}>
			<Routes>
				<Route index element={<HelmetUI title="Спектакли - Центр драматургии и режиссуры" description="Театр Центр драматургии и режиссуры" />} />
			</Routes>

			<Routes>
				<Route index element={<MainTitle>Пушкинская карта</MainTitle>} />
			</Routes>

			<div className={s.content}>
				<Routes>
					<Route index element={<ShowListPushkinSite />} />
					{/* <Route path=":id" element={<ShowInformation />} /> */}
				</Routes>
			</div>
		</div>

	);
}

export default PushkinPageSite;
