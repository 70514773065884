import React, { useState } from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { NavLink } from "react-router-dom";
import s from './SiteMenuItem.module.scss'
import cn from 'classnames'

const SiteMenuItem = ({name, url, submenu}) => {

	const [openSubMenu, setopenSubMenu] = useState(false);

	const eventToOpenSubMenu = () => {
		openSubMenu === false
			? setopenSubMenu(true)
			: setopenSubMenu(false)
	}

	const submenuItems = submenu.map(item =>
		<li>
			<a href={item.url}>
				{item.name}
			</a>
		</li>
	);

	return (
		<>
			<li
				className={
					cn(
						s.dropdownItem,
						openSubMenu === true && s.rotate
					)

				}
				onClick={() => eventToOpenSubMenu()}
			>
				{url ? (
					<a href={url}>
						{name}
					</a>
				) : (
					<span>
						{name}
					</span>
				)}
				{submenuItems.length ? (
					<ArrowLeftIcon fontSize='small' />
				) : (
					null
				)}
			</li>

			{submenuItems.length ? (
				<ul
					className={
						cn(
							s.subMenu,
							openSubMenu === true && s.openSubMenu
						)
					}
				>{submenuItems}</ul>
			) : null}

		</>
	);
}
export default SiteMenuItem;
