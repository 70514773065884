import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import s from './AdminMenu.module.scss'
import cn from 'classnames'
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../hook/AuthProvider';
import LogoutIcon from '@mui/icons-material/Logout';
const inflection = require( 'inflection' )

const AdminMenu = ({ clickToOpenLeftMenu }) => {

	const [openSubMenu, setopenSubMenu] = useState(false);

	const eventToOpenSubMenu = () => {
		openSubMenu === false
			? setopenSubMenu(true)
			: setopenSubMenu(false)
	}

	const navigate = useNavigate();
	const auth = useAuth()

	//Выход из панели администратора
	const logout = () => {
		auth.logout()
		localStorage.removeItem('user')
		navigate('/login')
	}

	return (
		<>
			<div className={s.menu}>

				<div className={s.button}>
					<IconButton sx={{ borderRadius: '0px', fontSize: '14px' }} disabled >
						{inflection.titleize(auth.user)}
					</IconButton>
					<IconButton sx={{ borderRadius: '0px', padding: '1px', marginBottom: '5px'}} variant="outlined" onClick={() => logout()}>
						<LogoutIcon />
					</IconButton>
				</div>

				<ul className={s.mainMenu}>
					<li onClick={clickToOpenLeftMenu}><NavLink to='./afisha'>Афиша</NavLink></li>
					<li onClick={clickToOpenLeftMenu}><NavLink to='./spektakli'>Спектакли</NavLink></li>
					<li onClick={clickToOpenLeftMenu}><NavLink to='./sobytiya'>События</NavLink></li>
					<li onClick={clickToOpenLeftMenu}><NavLink to='./istoriya'>История</NavLink></li>
					<li onClick={clickToOpenLeftMenu}><NavLink to='./laboratorii'>Лаборатории</NavLink></li>
					<li onClick={clickToOpenLeftMenu}><NavLink to='./proekty'>Проекты</NavLink></li>
					<li onClick={clickToOpenLeftMenu}><NavLink to='./employees'>Команда</NavLink></li>
					<li onClick={clickToOpenLeftMenu}><NavLink to='./ploshhadki'>Площадки</NavLink></li>
					<li onClick={clickToOpenLeftMenu}><NavLink to='./oficialnaya-informaciya'>Оф. информация</NavLink></li>
					<li onClick={clickToOpenLeftMenu}><NavLink to='./vakansii'>Вакансии</NavLink></li>
					<li onClick={clickToOpenLeftMenu}><NavLink to='./partnery'>Партнеры</NavLink></li>
					<li onClick={clickToOpenLeftMenu}><NavLink to='./cdr-cafe'>ЦДР кафе</NavLink></li>
					<li onClick={clickToOpenLeftMenu}><NavLink to='./kupit-bilety'>Купить билеты</NavLink></li>
					<li onClick={clickToOpenLeftMenu}><NavLink to='./kontakty'>Контакты</NavLink></li>
					<li onClick={clickToOpenLeftMenu}><NavLink to='./access'>Доступ</NavLink></li>
				</ul>

			</div>
		</>
	);
}

export default AdminMenu;
