import axios from 'axios'

class Api {
	constructor() {
		this._baseUrl = 'https://teatrcdr.ru/teatr-api';
	}

	//Изменить страницу
	updateCafe(data) {
		return axios.post(`${this._baseUrl}/cafe`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Получить страницу
	getCafe() {
		return axios.get(`${this._baseUrl}/cafe`)
			.then(res => res)
			.catch(err => err)
	}
}

const CafeAPI = new Api();

export default CafeAPI;