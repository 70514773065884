import React, { useState, useEffect } from 'react';
import s from './LoginPage.module.scss'
import HelmetUI from '../../ui/HelmetUI/HelmetUI';
import { useForm, Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SubTitle from '../../ui/SubTitle/SubTitle';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../hook/AuthProvider';
import UserAPI from '../../utils/UserAPI';

const LoginPage = () => {
	const [user, setUser] = useState('')
	const [showErrorMessage, setShowErrorMessage] = useState(false)

	const { control, handleSubmit, formState: { errors, isDirty, isValid }, setValue, getValues } = useForm(
		{
			mode: "onSubmit"
		}
	);
	const navigate = useNavigate();
	const auth = useAuth()

	useEffect(() => {
		if (localStorage.getItem('user') !== null) {
			auth.login(localStorage.getItem('user'))
			navigate('/admin/afisha', { replace: true })
			// navigate(window.location.pathname, { replace: true })
		}

	}, []);

	//Авторизоваться
	const login = (form) => {
		//console.log(form)
		setShowErrorMessage(false);

		UserAPI.login(form.login, form.password)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {

					localStorage.setItem('user', form.login);
					auth.login(localStorage.getItem('user'))
					// auth.login(form.login)
					navigate('/admin/afisha', { replace: true })
					// navigate(window.location.pathname, { replace: true })
				}

				if (result.response?.status === 404) {
					setShowErrorMessage(true);

				}
			})

	}


	return (
		<div>
			<div className={s.container}>

				<HelmetUI title="Авторизация" description="Театр Центр драматургии и режиссуры" robots="none" />


				<div className={s.content}>
					<SubTitle>Авторизация</SubTitle>
					<form
						className={s.form}
						onSubmit={handleSubmit(login)
						}>

						<Controller
							name="login"
							render={({ field }) =>
								<TextField
									sx={{ borderRadius: '0px' }}
									{...field}
									variant="outlined"
									label={"Логин"}
									size="small"
									fullWidth
									type='text'
									error={errors?.login?.message && true}
									helperText={errors?.login?.message ? errors?.login?.message : ' '} />}
							rules={{
								required: 'Поле обязательно для заполнения'
							}}
							control={control}
						/>

						<Controller
							name="password"
							render={({ field }) =>
								<TextField
									sx={{ borderRadius: '0px' }}
									{...field}
									variant="outlined"
									label={"Пароль"}
									size="small"
									fullWidth
									type='password'
									error={errors?.password?.message && true}
									helperText={errors?.password?.message ? errors?.password?.message : ' '} />}
							rules={{
								required: 'Поле обязательно для заполнения'
							}}
							control={control}
						/>


						{showErrorMessage === true &&
							<div className={s.showErrorMessage}>
								<>Логин или пароль введены неверно</>
							</div>}



						<div className={s.button}>
							<Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => navigate('/')}>
								<>Вернуться на сайт</>
							</Button>

							<Button sx={{ borderRadius: '0px' }} variant="outlined" type='submit'>
								Войти
							</Button>
						</div>

					</form>
				</div>
			</div>
		</div>
	);
}

export default LoginPage;
