import React, {useState, useEffect}from 'react';
import s from "./ShowPage.module.scss"
import { Link, Routes, Route } from "react-router-dom";
import Button from '@mui/material/Button';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';
import ShowList from '../../../components/Admin/Show/ShowList/ShowList';
import ShowForm from '../../../components/Admin/Show/ShowForm/ShowForm';

const ShowPage = () => {

	return (
		<>
			<Routes>
				<Route index element={<HelmetUI title="Спектакли" description="Управление спектаклями театра" robots="none" />} />
				<Route path="add" element={<HelmetUI title="Добавление спектакля" description="Добавление нового спектакля" robots="none" />} />
				<Route path=":id" element={<HelmetUI title="Редактирование спектакля" description="Редактирование спектакля" robots="none" />} />
			</Routes>

			<Routes>
				<Route index element={<MainTitle>Спектакли</MainTitle>} />
				<Route path=":id" element={<MainTitle back>Редактировать</MainTitle>} />
				<Route path="add" element={<MainTitle back>Добавление спектакля</MainTitle>} />
			</Routes>

			<Routes>
				<Route index element={
					<div className={s.add}>
						<Link to='./add'>
							<Button sx={{ borderRadius: '0px' }} variant="outlined">Добавить</Button>
						</Link>
					</div>
				} />
			</Routes>


			<div className={s.content}>
				<Routes>
					<Route index element={<ShowList />} />
					<Route path="add" element={<ShowForm />} />
					<Route path=":id" element={<ShowForm g1={true} />} />
				</Routes>
			</div>
		</>
	);
}

export default ShowPage;
