import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import UserAPI from '../../../../utils/UserAPI'
import s from './UserList.module.scss'
import ListItem from '../../../ListItem/ListItem';
import Search from '../../../Search/Search';
import Pagination from '@mui/material/Pagination';

const UserList = () => {
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchRequest, setSearchRequest] = useState('');
	const [page, setPage] = useState(1);
	const [maxPage, setMaxPage] = useState(0);
	const [maxListItem] = useState(10);
	const [beginIndex, setBeginIndex] = useState(0)
	const [endIndex, setEndIndex] = useState(10)

	//search test
	useEffect(() => {
		if (searchRequest !== '') {
			setMaxPage(0);
		} else {
			setMaxPage(Math.ceil(users.length / maxListItem))
		}
	}, [searchRequest, maxListItem, users])

	useEffect(() => {
		loading === false &&
			UserAPI.getAllUsers()
				.then(result => {
					// //console.log(result);
					if (result.status === 200) {
						setUsers(result.data)
						setMaxPage(Math.ceil(result.data.length / maxListItem))
						setLoading(true)
					}

					if (result.response?.status === 500) {
						//console.log(result.response.data)
					}
				})
	}, [loading, maxListItem]);

	const filterUsers = users?.filter((user) => {
		return (
			user?.fio?.toLowerCase().includes(searchRequest.toLowerCase()) ||
			user?.login?.toLowerCase().includes(searchRequest.toLowerCase())
		)
	})

	const showUsersByPage = filterUsers?.map((user, index) => {
		if (index >= beginIndex && index < endIndex) {
			return (
				<Link to={`./${user?.userID}`
				} key={user?.id}>
					<ListItem data={user} />
				</Link>
			)
		}
	})

	const showUsersWhithoutLimits = filterUsers?.map((user) => {
			return (
				<Link to={`./${user?.userID}`
				} key={user?.id}>
					<ListItem data={user} />
				</Link>
			)
	})

	const changePage = (event, value) => {
		setBeginIndex(value * maxListItem - maxListItem);
		setEndIndex(value * maxListItem)
		setPage(value);
	}

	const renderUserList = () => {
		return (
			<>
				<div className={s.search}>
					<Search setSearchRequest={setSearchRequest} />
				</div>

				<div className={s.container}>
					{maxPage !== 0 
					?showUsersByPage
					:showUsersWhithoutLimits}
					{showUsersByPage.length === 0 && <>Нет записей...</>}
				</div>

				<div className={s.pagination}>
					{maxPage !== 1 &&
						<Pagination sx={{ button: { borderRadius: '0px', color: 'text.primary' } }} count={maxPage} page={page} variant="outlined" shape="rounded" size="large" onChange={changePage} />
					}
				</div>
			</>

		)
	}


	return (
		<div>
			{loading === true
				?
				renderUserList()
				:
				<CircularProgress />
			}
		</div >
	);
}

export default UserList;
